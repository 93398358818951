import React, { useState, useEffect } from 'react';

import './styles/Login.css';
import api from '../services/api'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import logo from '../imgs/logo.png';
import FrTextInput from '../components/FrTextInput'
import { mCPF, mData, mNum } from '../utils/masks'

const swal = withReactContent(Swal)


function Cadastre_se() {

    const [cpf, setCpf] = useState('')
    const [data_nasc, setDataNasc] = useState('')

    function login(e) {
        e.preventDefault();
        //Pega valores do form
        const form = document.getElementById('loginForm');
        const data = new FormData(form);

        if (data.get('cpf') === '') {
            swal.fire('Informe seu cpf!', 'Informe o cpf.', 'warning')
            return
        }

        if (data.get('data_nasc') === '') {
            swal.fire('Informe sua data de nascimento!', 'Informe a data de nascimento.', 'warning')
            return
        }

        if (data.get('email') === '') {
            swal.fire('Informe seu email para receber o link de cadastro de senha!', 'Informe o email.', 'warning')
            return
        }

        //Converte FormData em JSON
        var object = {};

        data.forEach(function (value, key) {
            if (key === 'cpf')
                object[key] = mNum(value)
            else
                object[key] = value;
        });

        //Checa no servidor
        api.post('usuarios/cadastre_se', { ...object, host: window.location.href })
            .then(function (response) {
                console.log('usx:' + response.data[0].pk_usx)
                let pk_usu = response.data[0].pk_usx;
                if (pk_usu > 0) {
                    swal.fire('O link para cadastro de senha foi enviado para o seu email!', '', 'success')
                        .then((result) => {
                            if (result.value) {
                                window.location.href = '/apergs_associado/login'
                            }
                        })
                } else {
                    swal.fire('Associado não localizado!', '', 'error')
                }
            })
            .catch(function (error) {
                swal.fire('Associado não localizado!', '', 'error')
            });
    }


    const handleChange = (e) => {
        switch (e.target.name) {
            case 'cpf':
                setCpf(mCPF(e.target.value))
                break
            case 'data_nasc':
                setDataNasc(e.target.value)
                break
        }
    };


    const handleData = (e) => {
        setDataNasc(mData(e.target.value))

    };

    return (
        <div className="Login">
            <div className="form">
                <img alt="fr_logo" src={logo} title="Apergs" className="logo" />
                <form className="form-signin" id="loginForm" onSubmit={login}>
                    <div className="headerforgot">Ainda não tem login?
                <br />
                Informe os dados abaixo:
                </div>
                    <div className="form-label-group">
                        <FrTextInput
                            maxLength='18'
                            name='cpf'
                            id='cpf'
                            label='CPF'
                            variant='outlined'
                            size='small'
                            style={{ width: 190 }}
                            onChange={handleChange}
                            value={cpf}

                        />
                        <br />
                        <FrTextInput
                            name="data_nasc"
                            id="data_nasc"
                            label="Data Nascimento"
                            variant="outlined"
                            size="small"
                            style={{ width: 190 }}
                            onBlur={handleData}
                            value={data_nasc}
                            onChange={handleChange}

                        />
                        <br />
                        <FrTextInput
                            maxLength='50'
                            name='email'
                            id='email'
                            label='E-mail'
                            variant='outlined'
                            size='small'
                            style={{ width: 190 }}
                        />

                    </div>
                    <button className="loginButton" type="submit" rel="nofollow">
                        <span><p className='loginButtonText'>OK</p></span>
                    </button>

                </form>
            </div>
        </div>
    );

}

export default Cadastre_se;

import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(0),
      
    }
  },
  input: {
    borderRadius: 5,
    position: 'relative',
    backgroundColor: '#e1e6e6',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    border: '2px solid #004136',

    // fontSize: '0.9rem',
    // padding: '10px 26px 10px 12px',
    height: '13px',
    paddingLeft: '10px',
    paddingTop: '6px',
    paddingBottom:'8px',
    
    '&:focus': {
      borderRadius: 5,
      borderColor: '#004136',
      borderWidth: '2px',
      backgroundColor: '#e1e6e6',
      height: '13px'
      
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  margin: {
    marginRight: '6px',
    marginBottom: '10px',
    marginTop: '-1px'
    
  },
  label: {
    margin: theme.spacing(-1.5),
    marginLeft: theme.spacing(1.5),
    backgroundColor: '#e1e6e6',
    color: '#004136',
    zIndex: 3,
    top: '-2px',
    '&.MuiInputLabel-shrink' : {
      padding: '2px',
      top: '2px',
      color: '#004136'
    },
  },

}));

export default function CustomizedSelects(props) {
  const classes = useStyles();
  
  return (
    <>
      <FormControl className={classes.margin}>
        <InputLabel htmlFor="demo-customized-select-native" className={classes.label}>{props.label}</InputLabel>
        <NativeSelect
          id={props.id}
          value={props.value}
          onChange={props.handleChange}
          name={props.name}
          input={<BootstrapInput/>}
          {... props}
        >
          <option aria-label="Nenhum" value="" />
          {
            props.data.map((item) => {
              return (<option value={item.value}>{item.display}</option>)
            })
          }

        </NativeSelect>
      </FormControl>
    </>
  );
}
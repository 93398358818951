import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import api from '../services/api'

import './styles/Login.css';
import logo from '../imgs/logo.png';
import { mCPF, mNum } from '../utils/masks'
import FrTextInput from '../components/FrTextInput'


const swal = withReactContent(Swal)

function ChangePw() {

    const [cpf, setCpf] = useState('')

    function login(e) {
        e.preventDefault();
        //Pega valores do form
        const form = document.getElementById('loginForm');
        const data = new FormData(form);

        if (data.get('cpf') === '') {
            swal.fire('Informe seu CPF!', 'Informe seu CPF', 'warning')
            return
        }

        if (data.get('senha') === '') {
            swal.fire('Informe a senha!', 'Informe a senha', 'warning')
            return
        }
        //Converte FormData em JSON
        var object = {};

        data.forEach(function (value, key) {
            if (key === 'cpf')
                object[key] = mNum(value)
            else
                object[key] = value;
        });

        //Checa no servidor
        api.post('usuarios/changePw', object)
            .then(function (response) {
                swal.fire('Senha cadastrada com sucesso!', '', 'success')
                    .then((result) => {
                        if (result.value) {
                            window.location.href = '/apergs_associado/login'
                        }
                    })
            })
            .catch(function (error) {
                swal.fire('Senha não cadastrada! Associado não encontrado!', '', "error")
            });
    }

    const handleChange = (e) => {
        switch (e.target.name) {
            case 'cpf':
                setCpf(mCPF(e.target.value))
                break
        }
    };

    return (
        <div className="Login">
            <div className="form">
                <img alt="fr_logo" src={logo} title="Floresta Real" className="logo" />
                <form className="form-signin" id="loginForm" onSubmit={login}>
                    <div className="form-label-group">
                        <FrTextInput
                            maxLength='18'
                            name='cpf'
                            id='cpf'
                            label='Informe seu CPF'
                            variant='outlined'
                            size='small'
                            style={{ width: 190 }}
                            onChange={handleChange}
                            value={cpf}

                        />                    </div>
                    <div className="form-label-group">
                        <FrTextInput
                            name="senha"
                            id="senha"
                            label="Senha"
                            variant="outlined"
                            size="small"
                            style={{ width: 190 }}
                            input type="password"

                        />
                    </div>
                    <button className="loginButton" type="submit" rel="nofollow">
                        <span><p className='loginButtonText'>Cadastrar Senha</p></span>
                    </button>

                </form>
            </div>
        </div>
    );
}

export default ChangePw;

import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import NavBar from '../components/NavBar'
import FrTable from '../components/FrTable'
import FrTextInput from '../components/FrTextInput'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FooterDelphus from '../components/FooterDelphus'


import api from '../services/api'
import { paramsToObject, formToObject, populateForm, objectToQueryString, isEmpty } from '../utils/functions'

import './styles/DefaultMainScreen.css';
import './styles/Manut.css';

const swal = withReactContent(Swal)

function Arquivos() {
  const [data, setData] = useState([])
  const [filter, setFilter] = useState({})
  const [filtered, setFiltered] = useState(false)
  const [auth, setAuth] = useState(JSON.parse(localStorage['authApergs'] || JSON.stringify({ authenticated: false, user: 0 })));

  const columns = [
    { id: 'descricao', numeric: false, label: 'Descrição', align: 'left', minWidth: 300 },
    // { id: 'nome', numeric: false, label: 'Arquivo', align: 'left', minWidth: 300 },
    { id: 'format_data', numeric: false, label: 'Data Disponibilização', align: 'left', minWidth: 100, order: 'data' },
  ]

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })


      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)
      setFilter(query)
      populateForm(document.getElementById('searchForm'), query)

      swal.close()
      if (1 == 1) {
        let event = new Event('build');
        getData(event)
      }
    }

    fetchData()
  }, [])

  function getData(e) {
    e.preventDefault()
    var formData = new FormData(document.getElementById('searchForm'))
    var queryObject = formToObject(formData)
    queryObject.pk_ass = auth.pk_ass

    if (!isEmpty(queryObject)) {
      queryObject.buscar = true

      setFiltered(true)
      window.history.replaceState({ filtered: true }, 'filter', "/apergs_associado/arquivos?" + objectToQueryString(queryObject));


      swal.fire({
        html: <FrLoading text="Buscando dados..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      api.get('/arquivos', { params: queryObject }).then(r => {
        setData(r.data)
        swal.close()
      })
    } else {
      swal.fire('Nenhum filtro informado!', 'Informe ao menos um filtro para realizar a busca.', 'warning')
    }
  }

  function clearForm(e) {
    e.preventDefault();
    window.history.replaceState({ filtered: false }, 'filter', '/apergs_associado/arquivos');
    document.getElementById('searchForm').reset()
    setFilter({})
  }

  const handleChange = (e) => {
    const auxValues = { ...filter };
    auxValues[e.target.name] = e.target.value;
    setFilter(auxValues);
  };

  return (
    <div className="Manut">
      <NavBar />
      <div className="ManutContent">
        <div className="defaultHeader">
          <h3>Arquivos</h3>
          <UserOptions />
        </div>
        <br />
        <div className="filtersDiv">
          <form id="searchForm" onSubmit={getData}>
            <div>

              <FrTextInput
                value={auth.name || ''}
                label='Associado'
                variant='outlined'
                size='small'
                style={{ width: 300 }}
                disabled
              />


            </div>
            {/* <div>
              <button onClick={getData}>
                <Icon icon={ic_search} size={18} />
                Pesquisar
              </button>

              <button onClick={clearForm}>
                <Icon icon={ic_delete} size={18} />
                Limpar
              </button> */}
            {/* </div> */}
          </form>
        </div>

        <br />
        <div className="tableDiv">
          <FrTable readOnly={true} arquivos={true} regName="arquivos" columns={columns} searched={filtered} codeName="pk_arq" page={'Arquivos'} data={data} enableEdit={'N'} enableDel={'N'} emptyMessage={'Nenhum registro encontrado!'} />
        </div>
        <br />
        <FooterDelphus />
      </div>
    </div>
  );
}

export default Arquivos;

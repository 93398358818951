import React, { useEffect, useState, Component } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import NavBar from '../components/NavBar'
import FrSelect from '../components/FrSelect'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import api from '../services/api'
import './styles/DefaultReg.css';
import FooterDelphus from '../components/FooterDelphus';
import FrTable from '../components/FrTable'
import { paramsToObject } from '../utils/functions'
import FrTextInput from '../components/FrTextInput'



const swal = withReactContent(Swal)

function RegPev() {
  const [data, setData] = useState({})
  const [updateType, setUpdateType] = useState(false)
  const [comboIev, setComboIev] = useState([{ value: '', display: '' }])


  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)
      

      api.get('/eventos/pev?pk_pev=' + query.codigo).then(r => {
        if (r.data) {

          api.get('/eventos/iev?pk_eve=' + r.data[0].fk_eve).then(r => {
            if (r.data) {
              setComboIev(r.data)
            }
          })
  
          setData(r.data[0])
        }
      })

      swal.close()
    }

    fetchData()
  }, [])



  function consistData(data) {
    let required = []


    let errors = []

    required.forEach((curr, index) => {
      let fieldName = curr

      if (!data[curr]) return errors.push(fieldName)
    })


    return errors
  }

  function handleSubmit(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Deseja salvar " + (updateType ? 'as alterações' : 'a inclusão') + ' do registro?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        const auxValues = { ...data };

        api.post('/eventos/editPev', auxValues).then(r => {
          if (r.status === 200) {
            swal.fire({
              title: 'Registro Lançado',
              text: "O registro lançado com sucesso!",
              icon: 'success',
            }).then((result) => {
              if (result.value) {
                window.history.back()
              }
            })
          } else {
            swal.fire({
              title: 'Erro!',
              text: "Erro no lançamento!",
              icon: 'warning',
            })
          }
        })
      }
    })
  }

  function handleCancel(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        window.history.back()
      }
    })
  }



  const handleChange = (e) => {
    const auxValues = { ...data };
    auxValues[e.target.name] = e.target.value;
  
  setData(auxValues);
};


return (
  <div className="defaultReg">
    <NavBar />
    <div className="defaultRegContent">
      <div className="defaultHeaderReg">
        <UserOptions />
        <h3> Edição de Participação em Eventos</h3>

      </div>

      <br />
      <form id="regForm" onSubmit={handleSubmit}>


        <FrTextInput
          maxLength='50'
          value={data.qtd_deps || ''}
          color='#49573B'
          label='Qtd.Deps.'
          variant='outlined'
          size='small'
          style={{ width: 100 }}
          onChange={handleChange}
          name="qtd_deps"
          id="qtd_deps"

        />

        <FrSelect
          value={data.fk_iev || ''}
          onChange={handleChange}
          name="fk_iev"
          id="fk_iev"
          label='Item'
          data={comboIev}
          style={{ width: 300 }}
        />
      </form>

      <div className="regButtonsDiv">
        <button onClick={handleSubmit}>Salvar</button>
        <button onClick={handleCancel}>Cancelar</button>
      </div>
      <FooterDelphus />
    </div >
  </div >
);

}


export default RegPev;

import React, { useState } from 'react';
import { Icon } from 'react-icons-kit'
import { ic_chevron_right } from 'react-icons-kit/md/ic_chevron_right'
import { ic_chevron_left } from 'react-icons-kit/md/ic_chevron_left'
import { iosPeople } from 'react-icons-kit/ionicons/iosPeople'
import { office } from 'react-icons-kit/icomoon/office'

import Nav from 'react-bootstrap/Nav'
import Tooltip from '@material-ui/core/Tooltip';
import './index.css';
import icon from '../../imgs/icon.png';
import logo from '../../imgs/logo.png';
import { coinDollar } from 'react-icons-kit/icomoon/coinDollar'
import { calendar } from 'react-icons-kit/fa/calendar'
import { ic_event_note } from 'react-icons-kit/md/ic_event_note'
import api from '../../services/api'
import { fileText2 } from 'react-icons-kit/icomoon/fileText2'

function NavBar(props) {
    const [hide, setHide] = useState(true);


    function toggleNavBarHide(e, item) {
        e.preventDefault();


        setHide(!hide)
    }

    const [auth, setAuth] = useState(JSON.parse(localStorage['authApergs'] || JSON.stringify({ authenticated: false, user: 0 })));
    let link = "/apergs_associado/associados/registro?codigo=" + auth.pk_ass
    let linkReservas = "/apergs_associado/reservas?pk_ass=" + auth.pk_ass + '&buscar=true'
    let estatuto = ''
    let informativo = ''

    api.get('/reservas/pegaParametros').then(r => {
        estatuto = 'https://associado.apergs.org.br:21001/static/estatuto/' + r.data[0].estatuto
        informativo = r.data[0].informativo
    })


    function handleAbreArquivo(e, arquivo) {
        e.preventDefault()
        window.open(arquivo, '_blank')
    }


    return (
        <div className={"Navbar" + (hide ? '-hide' : '')}>
            <button onClick={toggleNavBarHide} className="toggle_navbar">
                {hide ? <Icon icon={ic_chevron_right} size={40} /> : <Icon icon={ic_chevron_left} size={40} />}
            </button>
            <Nav justify defaultActiveKey="/apergs_associado/home" className="flex-column">
                <Tooltip title="Tela Principal">
                    <Nav.Link href="/apergs_associado/home">
                        {hide ? <img alt='Expandir' src={icon} className="fr_icon_navbar" /> : <img alt='Encolher' src={logo} className="fr_logo_navbar" />}
                    </Nav.Link>
                </Tooltip>

                <div className="toggleMenu">

                    {/* <Tooltip title="Estatuto da Associação">
                        <Nav.Link onClick={(e) => handleAbreArquivo(e, estatuto)} className={(hide ? 'hide-' : '') + "toggleMenuItem"}>

                            <Icon icon={ic_event_note} size={30} onClick={(e) => handleAbreArquivo(e, estatuto)} />

                            {hide ? '' : <strong style={{ paddingLeft: '10px' }}>{'Estatuto da Associação'}</strong>}
                        </Nav.Link>
                    </Tooltip> */}

                    <Tooltip title="Normativas">
                        <Nav.Link href="/apergs_associado/normativas" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                            <Icon icon={ic_event_note} size={30} />
                            {hide ? '' : <strong style={{ paddingLeft: '10px' }}>{'Normativas'}</strong>}
                        </Nav.Link>
                    </Tooltip>

                    <Tooltip title="Informativos da Associação">
                        <Nav.Link href="/apergs_associado/informativos" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                            <Icon icon={ic_event_note} size={30} />
                            {hide ? '' : <strong style={{ paddingLeft: '10px' }}>{'Informativos da Associação'}</strong>}
                        </Nav.Link>
                    </Tooltip>


                    <Tooltip title="Dados Cadastrais">
                        <Nav.Link href={link} className={(hide ? 'hide-' : '') + "toggleMenuItem"}>

                            <Icon icon={iosPeople} size={30} />
                            {hide ? '' : <strong style={{ paddingLeft: '10px' }}>{'Dados Cadastrais'}</strong>}
                        </Nav.Link>
                    </Tooltip>

                    <Tooltip title="Dados Financeiros">
                        <Nav.Link href="/apergs_associado/financeiro" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                            <Icon icon={coinDollar} size={30} />
                            {hide ? '' : <strong style={{ paddingLeft: '10px' }}>{'Dados Financeiros'}</strong>}
                        </Nav.Link>
                    </Tooltip>

                    <Tooltip title="Sede Social">
                        <Nav.Link href={linkReservas} className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                            <Icon icon={office} size={30} />
                            {hide ? '' : <strong style={{ paddingLeft: '10px' }}>{'Sede Social'}</strong>}
                        </Nav.Link>
                    </Tooltip>

                    <Tooltip title="Eventos">
                        <Nav.Link href="/apergs_associado/eventos" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                            <Icon icon={calendar} size={30} />
                            {hide ? '' : <strong style={{ paddingLeft: '10px' }}>{'Eventos'}</strong>}
                        </Nav.Link>
                    </Tooltip>

                    <Tooltip title="Arquivos">
                        <Nav.Link href="/apergs_associado/arquivos" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                            <Icon icon={fileText2} size={30} />
                            {hide ? '' : <strong style={{ paddingLeft: '10px' }}>{'Arquivos'}</strong>}
                        </Nav.Link>
                    </Tooltip>

                </div>


            </Nav>

        </div>
    );
}

export default NavBar;
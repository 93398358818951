import Login from '../pages/Login';
import Home from '../pages/Home';
import ChangePw from '../pages/ChangePw';
import ForgotPw from '../pages/ForgotPw';
import RegAssociados from '../pages/RegAssociados';
import Cadastre_se from '../pages/Cadastre_se';
import Financeiro from '../pages/Financeiro';
import Reservas from '../pages/Reservas';
import RegReservas from '../pages/RegReservas';
import Eventos from '../pages/Eventos';
import RegEventos from '../pages/RegEventos';
import RegPev from '../pages/RegPev';
import Informativos from '../pages/Informativos';
import Arquivos from '../pages/Arquivos';
import Normativas from '../pages/Normativas';



export const routes = [
    {
        path: '/forgotPw',
        component: ForgotPw,
        protected: false,
        exact: true,
        checkPermission: false,
        register: false
    },

    {
        path: '/cadastre_se',
        component: Cadastre_se,
        protected: false,
        exact: true,
        checkPermission: false,
        register: false
    },

    {
        path: '/changePw',
        component: ChangePw,
        protected: false,
        exact: true,
        checkPermission: false,
        register: false
    },

    {
        path: '/login',
        component: Login,
        protected: false,
        exact: true,
        checkPermission: false,
        register: false
    },
    {
        path: '/home',
        component: Home,
        protected: true,
        exact: true,
        checkPermission: false,
        register: false
    },


    {
        path: '/associados/registro',
        component: RegAssociados,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

    {
        path: '/financeiro',
        component: Financeiro,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false
    },

    {
        path: '/reservas',
        component: Reservas,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false
    },

    {
        path: '/reservas/registro',
        component: RegReservas,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

    {
        path: '/eventos',
        component: Eventos,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false
    },

    {
        path: '/eventos/registro',
        component: RegEventos,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

    {
        path: '/regeventos/registro',
        component: RegPev,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

    {
        path: '/informativos',
        component: Informativos,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

    {
        path: '/arquivos',
        component: Arquivos,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

    // {
    //     path: '/tipoLocacao',
    //     component: TipoLocacao,
    //     protected: true,
    //     exact: true,
    //     checkPermission: true,
    //     register: false
    // },

    // {
    //     path: '/tipoLocacao/registro',
    //     component: RegTipoLocacao,
    //     protected: true,
    //     exact: true,
    //     checkPermission: true,
    //     register: true
    // },
    {
        path: '/normativas',
        component: Normativas,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

];

export const fallbackRoute = Home


import React, { useEffect, useState, Component } from 'react';
import { makeStyles, withStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { ptBR } from '@material-ui/core/locale';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import NavBar from '../components/NavBar'
import FrTextInput from '../components/FrTextInput'
import FrSelect from '../components/FrSelect'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import api from '../services/api'
import { paramsToObject, onlyNumbers, removeSpecialChar, validateEmail } from '../utils/functions'
import { mCEP, mData } from '../utils/masks'
import './styles/DefaultReg.css';
import FooterDelphus from '../components/FooterDelphus';
import Tabs from '../components/Tabs';
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Modal } from 'react-bootstrap'
import { Icon } from 'react-icons-kit'
import { ic_add_box } from 'react-icons-kit/md/ic_add_box'
import { ic_mode_edit } from 'react-icons-kit/md/ic_mode_edit'
import consultaCep from '../services/consultaCep'

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import { Link } from 'react-router-dom'

const swal = withReactContent(Swal)

function Associados() {
  const [data, setData] = useState({})
  const [dataDep, setDataDep] = useState([{ pk_ass: 0 }])
  const [updateType, setUpdateType] = useState(false)
  const [comboCid, setComboCid] = useState([{ value: '', display: '' }])
  const [comboSexo, setComboSexo] = useState([{ value: '', display: '' }])
  const [comboEstCivil, setComboEstCivil] = useState([{ value: '', display: '' }])
  const [comboLotacao, setComboLotacao] = useState([{ value: '', display: '' }])
  const [comboUnimed, setComboUnimed] = useState([{ value: '', display: '' }])
  const [comboGra, setComboGra] = useState([{ value: '', display: '' }])
  const [modalDependentes, setModalDependentes] = useState({ show: false, edit: false });
  const [nomeAssociado, setNomeAssociado] = useState('');
  const [data_nasc, setDataNasc] = useState('')
  const [nome, setNome] = useState('')
  const [fk_gra, setGra] = useState('')
  const [unimed_sos, setUnimedSos] = useState('')
  const [pkDependente, setPkDependente] = useState('')
  const [comboSit, setComboSit] = useState([{ value: '', display: '' }])

  const columns = [
    { id: 'nome', numeric: false, label: 'Nome', align: 'left', minWidth: 300 },
    { id: 'nomegra', numeric: false, label: 'Parentesco', align: 'left', minWidth: 300 },

  ]


  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)

      setComboSexo([
        {
          "value": "F",
          "display": "Feminino",
        },
        {
          "value": "M",
          "display": "Masculino",
        },])

      setComboUnimed([
        {
          "value": "S",
          "display": "Sim",
        },
        {
          "value": "N",
          "display": "Não",
        },])

      setComboEstCivil([

        {
          "value": "S",
          "display": "Solteiro(a)",
        },
        {
          "value": "C",
          "display": "Casado(a)",
        },

        {
          "value": "D",
          "display": "Divorciado(a)",
        },

        {
          "value": "V",
          "display": "Viúvo(a)",
        },

        {
          "value": "P",
          "display": "Separado(a)",
        },

      ])

      setComboLotacao(await api.getComboData('lotacoes'))
      setComboCid(await api.getComboData('cidades'))
      setComboGra(await api.getComboData('grau_parentesco'))
      setComboSit(await api.getComboData('situacoes'))


      if (query.codigo > 0) {
        setUpdateType(true)

        api.get('/associados/buscadependentes?fk_ass=' + query.codigo).then(r => {
          console.log(r.data)
          if (r.data) {
            setDataDep(r.data)
          }
        })

        api.get('/associados?pk_ass=' + query.codigo).then(r => {
          if (r.data[0]) {
            console.log(r.data[0])
            setData(r.data[0])
            setNomeAssociado(r.data[0].nome)
            swal.close()
          } else {
            swal.fire('Código inválido', 'Código informado não encontrado!', 'warning')
              .then((result) => {
                if (result.value) {
                  window.history.back()
                }
              })
          }
        })
      } else swal.close()
    }

    fetchData()
  }, [])


  async function showModalDependentes(e, codigo) {
    e.preventDefault();
    let edit = false;
    if (Number(codigo) > 0) {
      await api.get('/associados/buscadependentes?pk_ass=' + codigo).then(r => {

        if (r.data[0]) {
          setDataNasc(r.data[0].format_data_nasc);
          setNome(r.data[0].nome);
          setGra(r.data[0].fk_gra);
          setUnimedSos(r.data[0].unimed_sos);
          setPkDependente(codigo);

          edit = true;
        }
      })
    }
    else {
      setDataNasc('');
      setNome('');
      setGra('');
      setUnimedSos('');
      setPkDependente('');

      document.getElementById("registroDependentes").reset();
    }

    setModalDependentes({ show: true, edit: edit })
  }

  function handleSaveDependentes(e) {
    e.preventDefault()
    swal.fire({
      title: 'ATENÇÃO',
      text: modalDependentes.edit ? "As alterações no registro serão salvas. Confirma?" : "O registro será incluído. Confirma?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) submitDataDependentes(e)
    });
  }

  function submitDataDependentes(e) {
    e.preventDefault();
    const form = document.getElementById('registroDependentes');
    const dataD = new FormData(form);

    if (dataD.get('nome') === '') {
      swal.fire('Informe o nome do dependente!', 'Informe o nome do dependente.', 'warning')
      return
    }

    if (dataD.get('data_nasc') === '') {
      swal.fire('Informe a data de nascimento!', 'Informe a data de nascimento.', 'warning')
      return
    }

    if (dataD.get('fk_gra') === 0) {
      swal.fire('Informe o grau de parentesco!', 'Informe o grau de parentesco.', 'warning')
      return
    }

    if (dataD.get('unimed_sos') === '') {
      swal.fire('Informe o campo Unimed SOS!', 'Informe o campo Unimed SOS.', 'warning')
      return
    }


    var object = {};
    object['data_nasc'] = dataD.get('data_nasc');
    object['fk_ass'] = data.pk_ass;
    object['nome'] = dataD.get('nome');
    object['fk_gra'] = dataD.get('fk_gra');
    object['unimed_sos'] = dataD.get('unimed_sos');
    object['pk_ass'] = pkDependente;

    if (modalDependentes.edit) {
      api.post('/associados/dependentesedit', object).then(r => {
        if (r.status === 200) {
          swal.fire({
            title: 'Registro Alterado',
            text: "O registro foi alterado com sucesso!",
            icon: 'success',
          }).then((result) => {
            if (result.value) {
              setModalDependentes({ show: false, edit: false })
              api.get('/associados/buscadependentes?fk_ass=' + data.pk_ass).then(r => {
                if (r.data) {
                  setDataDep(r.data)
                }
              })

            }
          })
        } else {
          swal.fire({
            title: 'Erro!',
            text: "O registro não foi alterado!",
            icon: 'warning',
          })
        }
      })
    } else {
      console.log(object)

      api.post('/associados/dependentesadd', object).then(r => {
        if (r.status === 200) {
          swal.fire({
            title: 'Registro Adicionado',
            text: "O registro foi adicionado com sucesso!",
            icon: 'success',
          }).then((result) => {
            if (result.value) {
              setModalDependentes({ show: false, edit: false })
              api.get('/associados/buscadependentes?fk_ass=' + data.pk_ass).then(r => {
                if (r.data) {
                  setDataDep(r.data)
                }
              })

            }
          })
        } else {
          swal.fire({
            title: 'Erro!',
            text: "O registro não foi adicionado!",
            icon: 'warning',
          })
        }
      })
    }

  }

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: '#004136',
      color: theme.palette.common.white,

    },
    body: {
      fontSize: 14,
      borderRadius: 5,
      borderColor: '#004136',
      border: '2px solid',
      color: 'black',

    },
  }))(TableCell);

  const theme = createMuiTheme({
    palette: {
      primary: { main: '#004136' },
    },
  }, ptBR);



  const useStyles = makeStyles({
    root: {
      backgroundColor: '#e1e6e6',
      borderRadius: 5,
      borderColor: '#004136',
      border: '2px solid',
      minHeight: '18vh',

      "& .Mui-selected": {
        backgroundColor: '#004136',
        borderColor: '#004136',
        border: '2px solid',

      },
      "& .Mui-selected:hover": {
        backgroundColor: '#004136',
        borderColor: '#004136',
        border: '2px solid',

      },
      width: '100%',
    },
    container: {
      maxHeight: '58vh',
      minHeight: '58vh',
      borderColor: '#004136',
      border: '2px solid',
    },
    row: {
      height: '2vh',
      borderColor: '#004136',
      borderBottom: '1px solid',
    },
  });

  const classes = useStyles();

  function consistData(data) {
    let required = [
      'nome',
      'rg',
      'fk_lot',
      'endereco_resid',
      'bairro_resid',
      'fk_cid_resid',
      'cep_resid',
      'telefone_resid'
    ]

    let errors = []

    required.forEach((curr, index) => {
      let fieldName = curr
      if (curr === 'fk_lot') fieldName = 'Lotação'
      if (curr === 'endereco_resid') fieldName = 'Endereço Residencial'
      if (curr === 'bairro_resid') fieldName = 'Bairro Residencial'
      if (curr === 'fk_cid_resid') fieldName = 'Cidade Residencial'
      if (curr === 'cep_resid') fieldName = 'CEP Residencial'
      if (curr === 'telefone_resid') fieldName = 'Telefone Residencial'

      if (!data[curr]) return errors.push(fieldName)
    })


    return errors
  }

  function handleSubmit(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Deseja salvar " + (updateType ? 'as alterações' : 'a inclusão') + ' do registro?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        const auxValues = { ...data };
        console.log(auxValues)
        auxValues.cep_resid = onlyNumbers(auxValues.cep_resid)

        let errors = consistData(auxValues)
        if (errors.length === 0) {
          if (updateType) {
            api.post('/associados/edit', auxValues).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: 'Registro Alterado',
                  text: "O registro foi alterado com sucesso!",
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    window.history.back()
                  }
                })
              } else {
                swal.fire({
                  title: 'Erro!',
                  text: "O registro não foi alterado!",
                  icon: 'warning',
                })
              }
            })
          } else {
            api.post('/associados/add', auxValues).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: 'Registro Adicionado',
                  text: "O registro foi adicionado com sucesso!",
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    window.history.back()
                  }
                })
              } else {
                swal.fire({
                  title: 'Erro!',
                  text: "O registro não foi adicionado!",
                  icon: 'warning',
                })
              }
            })
          }
        } else {
          swal.fire({
            title: 'Erro!',
            text: "Verifique o(s) campo(s) " + errors.join(','),
            icon: 'warning',
          })
        }
      }
    })
  }

  function handleCancel(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        window.history.back()
      }
    })
  }


  function closeModal(e) {
    e.preventDefault();
    setModalDependentes({ show: false, edit: false })


  }

  const handleChange = (e) => {
    const auxValues = { ...data };
    switch (e.target.name) {
      case 'whatsapp':
        auxValues[e.target.name] = (auxValues[e.target.name] === 'S' ? 'N' : 'S')
        break

      case 'envia_email_com':
        if (!validateEmail(auxValues.email_comerc)) {
          swal.fire({
            title: 'Erro!',
            text: "Informe um e-mail válido!",
            icon: 'warning',
          })
        }
        auxValues[e.target.name] = (auxValues[e.target.name] === 'S' ? 'N' : 'S')
        break

      case 'envia_email_resid':
        if (!validateEmail(auxValues.email_resid)) {
          swal.fire({
            title: 'Erro!',
            text: "Informe um e-mail válido!",
            icon: 'warning',
          })
        }
        auxValues[e.target.name] = (auxValues[e.target.name] === 'S' ? 'N' : 'S')
        break

      case 'cep_resid':
        auxValues[e.target.name] = mCEP(e.target.value)
        break

      case 'format_data_nasc':
        auxValues[e.target.name] = mData(e.target.value)
        break

      case 'nome':
        auxValues[e.target.name] = removeSpecialChar(e.target.value);
        break

      default: auxValues[e.target.name] = e.target.value;
    }
    setData(auxValues);
  };


  const handleChangeDep = (e) => {
    switch (e.target.name) {
      case 'data_nasc':
        setDataNasc(e.target.value)
        break

      case 'nome':
        setNome(e.target.value)
        break

      case 'fk_gra':
        setGra(e.target.value)
        break

      case 'unimed_sos':
        setUnimedSos(e.target.value)
        break

    }
  };

  const handleData = (e) => {
    setDataNasc(mData(e.target.value))

  };

  function handleCep(e) {
    e.preventDefault();
    if (e.target.value) {
      if (e.target.value) {

        let endereco = 'endereco_resid';
        let bairro = 'bairro_resid';
        let fk_cid = 'fk_cid_resid';

        consultaCep.get('/' + onlyNumbers(e.target.value) + '/json').then(async r => {
          console.log(r)
          if (r.data.erro) {
            swal.fire('CEP inválido', 'Informe um CEP válido!', 'warning')
          } else {
            const auxValues = { ...data };
            auxValues[endereco] = r.data.logradouro;
            auxValues[bairro] = r.data.bairro;

            await api.get('/cidades?nome=' + r.data.localidade).then(r => {
              if (r.data.length > 0)
                auxValues[fk_cid] = r.data[0].pk_cid
            })

            setData(auxValues)
          }
        }).catch(err => {
          swal.fire('CEP inválido', 'Informe um CEP válido!', 'warning')
        })
      }
    }
  }



  return (
    <div className="defaultReg">
      <NavBar />
      <div className="defaultRegContent">
        <div className="defaultHeaderReg">
          <UserOptions />
          <h3> Edição de Associado</h3>

        </div>
        <br />
        <form id="regForm" onSubmit={handleSubmit}>

          <Tabs aba1_caption="PRINCIPAL" aba1_hidden={false} Aba1={<div style={{ marginLeft: '-20px' }}>
            <FrTextInput
              maxLength='100'
              value={data.nome || ''}
              onChange={handleChange}
              name='nome'
              id='nome'
              color='#49573B'
              label='Nome'
              variant='outlined'
              size='small'
              required
              style={{ width: 300 }}
            />
            <FrTextInput
              maxLength='20'
              value={data.cpf || ''}
              onChange={handleChange}
              name='cpf'
              id='cpf'
              color='#49573B'
              label='CPF'
              variant='outlined'
              size='small'
              style={{ width: 170 }}
              disabled
            />

            <FrTextInput
              maxLength='20'
              value={data.rg || ''}
              onChange={handleChange}
              name='rg'
              id='rg'
              color='#49573B'
              label='RG'
              variant='outlined'
              size='small'
              style={{ width: 170 }}
            />
            <br />


            <FrTextInput
              maxLength='100'
              value={data.nome_pai || ''}
              onChange={handleChange}
              name='nome_pai'
              id='nome_pai'
              color='#49573B'
              label='Nome do Pai'
              variant='outlined'
              size='small'
              required
              style={{ width: 300 }}
            />

            <FrTextInput
              maxLength='100'
              value={data.nome_mae || ''}
              onChange={handleChange}
              name='nome_mae'
              id='nome_mae'
              color='#49573B'
              label='Nome da Mãe'
              variant='outlined'
              size='small'
              required
              style={{ width: 300 }}
            />
            <br />


            <FrTextInput
              maxLength='100'
              value={data.email_resid || ''}
              onChange={handleChange}
              name='email_resid'
              id='email_resid'
              color='#49573B'
              label='Email Pessoal'
              variant='outlined'
              size='small'
              required
              style={{ width: 270 }}
            />

            <FormControlLabel
              control={
                <Checkbox
                  value={data.envia_email_resid || ''}
                  checked={data.envia_email_resid === 'S'}
                  name='envia_email_resid'
                  id='envia_email_resid'
                  size='small'
                  onChange={handleChange}
                  color="primary"
                />
              }
              label="Enviar Email"
            />



            <FrTextInput
              maxLength='100'
              value={data.email_comerc || ''}
              onChange={handleChange}
              name='email_comerc'
              id='email_comerc'
              color='#49573B'
              label='Email Funcional'
              variant='outlined'
              size='small'
              required
              style={{ width: 270 }}
            />

            <FormControlLabel
              control={
                <Checkbox
                  value={data.envia_email_com || ''}
                  checked={data.envia_email_com === 'S'}
                  name='envia_email_com'
                  id='envia_email_com'
                  size='small'
                  onChange={handleChange}
                  color="primary"
                />
              }
              label="Enviar Email"
            />

            <br />


            <FrTextInput
              maxLength='500'
              value={data.celulares || ''}
              onChange={handleChange}
              name='celulares'
              id='celulares'
              color='#49573B'
              label='Celulares'
              variant='outlined'
              size='small'
              disabled
              style={{ width: 470 }}
            />



            <br />

            <FrSelect
              value={data.sexo || ''}
              onChange={handleChange}
              name="sexo"
              id="sexo"
              label='Sexo'
              data={comboSexo}
              style={{ width: 100 }}
            />

            <FrSelect
              value={data.estado_civil || ''}
              onChange={handleChange}
              name="estado_civil"
              id="estado_civil"
              label='Estado Civil'
              data={comboEstCivil}
              style={{ width: 130 }}
            />


            <FrTextInput
              maxLength='20'
              value={data.format_data_nasc || ''}
              onChange={handleChange}
              name='format_data_nasc'
              id='format_data_nasc'
              color='#49573B'
              label='Data Nascimento'
              variant='outlined'
              size='small'
              required
              style={{ width: 150 }}
            />


            <FrTextInput
              maxLength='20'
              value={data.nro_oab || ''}
              onChange={handleChange}
              name='nro_oab'
              id='nro_oab'
              color='#49573B'
              label='Nr. OAB'
              variant='outlined'
              size='small'
              required
              style={{ width: 100 }}
            />

            <FrSelect
              value={data.fk_lot || ''}
              onChange={handleChange}
              name="fk_lot"
              id="fk_lot"
              label='Lotação'
              data={comboLotacao}
              style={{ width: 250 }}
            />

            <FrSelect
              value={data.fk_sit || ''}
              onChange={handleChange}
              name="fk_sit"
              id="fk_sit"
              label='Situação'
              data={comboSit}
              style={{ width: 170 }}
            />


            <FormControlLabel
              control={
                <Checkbox
                  value={data.whatsapp || ''}
                  checked={data.whatsapp === 'S'}
                  name='whatsapp'
                  id='whatsapp'
                  size='small'
                  onChange={handleChange}
                  color="primary"
                />
              }
              label="Habilitado para receber mensagens de whatsapp"
            />

          </div>}

            aba2_caption="ENDEREÇO" aba2_hidden={false} Aba2=
            {<div style={{ marginLeft: '-20px' }}>

              Residencial
              <br />
              <br />


              <FrTextInput
                maxLength='11'
                value={data.cep_resid || ''}
                onChange={handleChange}
                onBlur={handleCep}
                name='cep_resid'
                id='cep_resid'
                color='#49573B'
                label='CEP'
                variant='outlined'
                size='small'
                required
                style={{ width: 100 }}
              />

              <FrTextInput
                maxLength='150'
                value={data.endereco_resid || ''}
                onChange={handleChange}
                name='endereco_resid'
                id='endereco_resid'
                color='#49573B'
                label='Endereço'
                variant='outlined'
                size='small'
                required
                style={{ width: 310 }}
              />
              <br />
              <FrTextInput
                maxLength='50'
                value={data.bairro_resid || ''}
                onChange={handleChange}
                name='bairro_resid'
                id='bairro_resid'
                color='#49573B'
                label='Bairro'
                variant='outlined'
                size='small'
                required
                style={{ width: 200 }}
              />

              <FrSelect
                value={data.fk_cid_resid || ''}
                onChange={handleChange}
                name="fk_cid_resid"
                id="fk_cid_resid"
                label='Cidade'
                data={comboCid}
                style={{ width: 200 }}
                required
              />

              <FrTextInput
                maxLength='100'
                value={data.telefone_resid || ''}
                onChange={handleChange}
                name='telefone_resid'
                id='telefone_resid'
                // color='#49573B'
                label='Telefone Residencial'
                variant='outlined'
                size='small'
                required
                style={{ width: 150 }}
              />

            </div>

            }
            aba3_caption="DEPENDENTES" aba3_hidden={false} Aba3={

              <div style={{ paddingBottom: '30px' }}>


                <div className={(modalDependentes.show === true) ? 'ModalShow' : 'ModalHide'} tabIndex="-1">
                  <div >

                    <Modal.Dialog className="Modal">
                      <div>
                        <Modal.Header className="ModalBg">
                          <div className="ModalHeader">
                            <h3 className="headerModal">Registro de Dependente</h3>
                          </div>
                        </Modal.Header>
                        <Modal.Body className="ModalBg" >
                          <div >
                            <form id="registroDependentes" name="registroDependentes" onSubmit={submitDataDependentes}>
                              <div>
                                <label >Associado: {nomeAssociado}</label>
                              </div>
                              <br />
                              <FrTextInput
                                maxLength='100'
                                name='nome'
                                value={nome}
                                id='nome'
                                label='Nome do Dependente'
                                size='small'
                                required
                                variant='outlined'
                                style={{ width: 250 }}
                                onChange={handleChangeDep}

                              />
                              <br />
                              <FrSelect
                                name="fk_gra"
                                value={fk_gra}
                                id="fk_gra"
                                label='Grau de Parentesco'
                                data={comboGra}
                                style={{ width: 250 }}
                                required
                                onChange={handleChangeDep}

                              />


                              <br />

                              <FrTextInput
                                maxLength='20'
                                value={data_nasc}
                                onChange={handleChangeDep}
                                onBlur={handleData}
                                name='data_nasc'
                                id='data_nasc'
                                label='Data Nascimento'
                                variant='outlined'
                                size='small'
                                required
                                style={{ width: 150 }}
                              />

                              <FrSelect
                                name="unimed_sos"
                                id="unimed_sos"
                                value={unimed_sos}
                                label='Unimed SOS'
                                data={comboUnimed}
                                style={{ width: 100 }}
                                disabled
                                onChange={handleChangeDep}

                              />
                            </form>
                          </div>

                        </Modal.Body>

                        <Modal.Footer className="ModalBg">
                          <div className="regButtonsDiv">
                            {/* <button onClick={handleSaveDependentes}>Salvar</button> */}
                            <button onClick={closeModal}>Sair</button>
                          </div>
                        </Modal.Footer>
                      </div>
                    </Modal.Dialog>
                  </div>
                </div>


                <div className="tableDiv" hidden={modalDependentes.show} >

                  {/* <div className="regButtons" style={{ marginTop: '30px' }}>
                    <button onClick={showModalDependentes}>
                      <Icon icon={ic_add_box} size={18} />
                  Novo Registro
                </button>
                  </div>
                  <br /> */}
                  {/* <TableModal regName="" columns={columns} searched={false} codeName="pk_ass" page={''} data={dataDep} /> */}

                  {/* <ThemeProvider theme={theme}> */}

                  {/* <Paper className={classes.root}> */}
                  <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell
                            style={{ minWidth: 100, width: 100 }}
                          >
                            Opções
                          </StyledTableCell>

                          <StyledTableCell
                            key='nome'
                          >
                            Nome
                          </StyledTableCell>

                          <StyledTableCell
                            key='nomegra'
                          >
                            Parentesco
                          </StyledTableCell>

                        </TableRow>
                      </TableHead>
                      <TableBody>

                        {dataDep.map((row) => {

                          return (
                            <TableRow tabIndex={-1} key={'pk_ass'} >

                              <TableCell className={classes.row}>
                                <Tooltip title="Visualizar">
                                  <Link to={""}>
                                    <Icon className="tableEdit" icon={ic_mode_edit} size={22} onClick={(e) => showModalDependentes(e, row['pk_ass'])} />
                                  </Link>
                                </Tooltip>
                              </TableCell>

                              {columns.map((column) => {
                                const value = row[column.id];
                                return (
                                  <TableCell className={classes.row} key={column.id} align={column.align}>
                                    {column.format && typeof value === 'number' ? column.format(value) : value}
                                  </TableCell>
                                );
                              })
                              }
                            </TableRow>
                          )
                        }
                        )}


                      </TableBody>
                    </Table>
                  </TableContainer>
                  {/* <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={data.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                      /> */}
                  {/* </Paper> */}

                  {/* </ThemeProvider> */}

                </div>

              </div>

            }


            aba4_caption="IDENTIFICAÇÃO" aba4_hidden={true}
            aba5_caption="IDENTIFICAÇÃO" aba5_hidden={true}
            aba6_caption="IDENTIFICAÇÃO" aba6_hidden={true}

          >
          </Tabs>


        </form>
        <div className="regButtonsDiv">
          <button onClick={handleSubmit}>Salvar</button>
          <button onClick={handleCancel}>Cancelar</button>
        </div>
        <FooterDelphus />
      </div >
    </div >
  );
}

export default Associados;

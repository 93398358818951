import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
// Icons
import TablePagination from '@material-ui/core/TablePagination';
import { Icon } from 'react-icons-kit'
import { plus } from 'react-icons-kit/fa/plus'
import { minus } from 'react-icons-kit/fa/minus'
import TableContainer from '@material-ui/core/TableContainer';
import Input from "@material-ui/core/Input";


const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    // marginTop: theme.spacing(3),
    // overflowX: "auto",
    // overflowY: "scroll"
  },
  table: {
    // minWidth: 250
    width: "100%",
    height: 50,
    // overflowX: "auto"

  },
  container: {
    maxHeight: "80%",
    minHeight: "80%",
    // overflowY: "scroll"
  },
  // selectTableCell: {
  //   width: 60
  // },
  tableCell: {
    width: 130,
    height: 20
  },
  input: {
    width: 25,
    height: 20
  }
}));



const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#004136',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 11,

  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);


const CustomTableCell = ({ row, name, onChange, onClick }) => {
  const classes = useStyles();
  const { isEditMode } = row;
  return (
    <TableCell align="left" className={classes.tableCell}>

      {(name == 'qtd') ? (
        <Icon className="tableDelete" icon={minus} size={10} onClick={(e) => onClick(e, row.id, -1)} />
      ) : <></>}

      {1 == 1 ? (
        <Input
          value={row[name]}
          name={name}
          onChange={e => onChange(e, row)}
          className={classes.input}
        />
      ) : (
        row[name]
      )}

      {/* {row[name]} */}
      {(name == 'qtd') ? (
        <Icon className="tableDelete" icon={plus} size={10} onClick={(e) => onClick(e, row.id, 1)} />
      ) : <></>}

      {(name !== 'qtd') ?
        row[name] : <></>}


    </TableCell>
  );
};


export default function TableEdicao2(props) {

  // const CustomTableCell = ({ row, name, onChange }) => {
  //   const classes = useStyles();
  //   const { isEditMode } = row;
  //   return (
  //     <TableCell align="left" className={classes.tableCell}>

  //       {(name == 'qtd') ? (
  //         <Icon className="tableDelete" icon={minus} size={10} onClick={(e) => incrementa(e, row.id, -1)} />
  //       ) : <></>}

  //       {1 == 1 ? (
  //         <Input
  //           value={row[name]}
  //           name={name}
  //           onChange={e => onChange(e, row)}
  //           className={classes.input}
  //         />
  //       ) : (
  //         row[name]
  //       )}

  //       {/* {row[name]} */}
  //       {(name == 'qtd') ? (
  //         <Icon className="tableDelete" icon={plus} size={10} onClick={(e) => incrementa(e, row.id, 1)} />
  //       ) : <></>}

  //       {(name !== 'qtd') ?
  //         row[name] : <></>}


  //     </TableCell>
  //   );
  // };




  const [rows, setRows] = React.useState([])
  const [previous, setPrevious] = React.useState({});
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    setRows(props.data)
    // console.log(props.data)
  }, [props.data])


  const atualiza = (id, n) => {

    const newRows = rows.map(row => {
      if (row.id === id) {
        return { ...row, isEditMode: false, qtd: (row.qtd + n) < 0 ? 0 : (row.qtd + n) > row.qtd_estoque ? row.qtd : row.qtd + n };
      }
      return row;
    });
    setRows(newRows);
    console.log(newRows)
    props.setDataMat3(newRows)
  }


  const onClick = (e, id, n) => {
    atualiza(id, n)

    setRows(state => {
      return rows.map(row => {
        if (row.id === id) {
          return { ...row, isEditMode: false, qtd: (Number(row.qtd) + n) < 0 ? 0 : (Number(row.qtd) + n) > row.qtd_estoque ? Number(row.qtd) : Number(row.qtd) + n };

        }
        return row;
      });
    });

  };

  const onChange = (e, row) => {
    let value = e.target.value;
    const name = e.target.name;
    if (value > row.qtd_estoque)
      value = row.qtd_estoque
    const { id } = row;
    const newRows = rows.map(row => {
      if (row.id === id) {
        return { ...row, [name]: value };
      }
      return row;
    });
    setRows(newRows);
    // console.log(newRows)
    props.setDataMat3(newRows)
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>

        <Table className={classes.table} size="small" aria-label="a dense table">

          <TableHead>
            <StyledTableRow>
              <StyledTableCell align="left">Material/Equipamento</StyledTableCell>
              <StyledTableCell align="left">Qtd.</StyledTableCell>
              <StyledTableCell align="left">Qtd.Estoque</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell className={classes.selectTableCell}>{row.nomemat}</StyledTableCell>
                <CustomTableCell {...{ row, name: "qtd", onChange, onClick }} />
                <StyledTableCell className={classes.selectTableCell}>{row.qtd_estoque}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableContainer>
    </Paper>
  );
}

import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import NavBar from '../components/NavBar'
import FrTable from '../components/FrTable'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FooterDelphus from '../components/FooterDelphus'


import api from '../services/api'


import { paramsToObject, formToObject, populateForm, objectToQueryString, isEmpty } from '../utils/functions'


import './styles/DefaultMainScreen.css';
import './styles/Manut.css';

const swal = withReactContent(Swal)

function Eventos() {
  const [data, setData] = useState([])
  const [filter, setFilter] = useState({})
  const [filtered, setFiltered] = useState(false)
  const [auth, setAuth] = useState(JSON.parse(localStorage['authApergs'] || JSON.stringify({ authenticated: false, user: 0 })));


  const columns = [
    { id: 'data', numeric: false, label: 'Data/Hora', align: 'left', minWidth: 100 },
    { id: 'descricao', numeric: false, label: 'Descrição do Evento', align: 'left', minWidth: 250 },
    { id: 'local', numeric: false, label: 'Local', align: 'left', minWidth: 180 },
    { id: 'participacao', numeric: false, label: 'Participação', align: 'left', minWidth: 220, icon: 'true' },
    { id: 'exige_confirmacao', numeric: false, label: 'Exige Confirmação', align: 'left', minWidth: 200 },

  ]

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })


      swal.close()
      let event = new Event('build');
      getData(event)
    }

    fetchData()
  }, [])

  function getData(e) {
    e.preventDefault()

    setFiltered(true)
    window.history.replaceState({ filtered: true }, 'filter', "/apergs_associado/eventos?pk_ass=" + auth.pk_ass);


    swal.fire({
      html: <FrLoading text="Buscando dados..." />,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false
    })

    api.get('/eventos?pk_ass=' + auth.pk_ass).then(r => {
      setData(r.data)
      swal.close()
    })
  }

  function clearForm(e) {
    e.preventDefault();
    console.log('limpa');
    window.history.replaceState({ filtered: false }, 'filter', '/apergs_associado/eventos');
    document.getElementById('searchForm').reset()
    setFilter({})
  }

  const handleChange = (e) => {
    const auxValues = { ...filter };
    auxValues[e.target.name] = e.target.value;
    setFilter(auxValues);
  };



  return (
    <div className="Manut">
      <NavBar />
      <div className="ManutContent">
        <div className="defaultHeader">
          <h3>Participação em Eventos</h3>
          <UserOptions />
        </div>

        <br />
        <div className="tableDiv">
          <FrTable eventos={true} regName="eventos" columns={columns} searched={filtered} codeName="pk_eve" page={'Eventos'} data={data} enableDelete={false} />
        </div>
        <br />
        <FooterDelphus />
      </div>
    </div>
  );
}

export default Eventos;

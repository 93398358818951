import React, { useEffect, useState, Component } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import NavBar from "../components/NavBar";
import FrSelect from "../components/FrSelect";
import UserOptions from "../components/UserOptions";
import FrLoading from "../components/FrLoading";
import api from "../services/api";
import "./styles/DefaultReg.css";
import FooterDelphus from "../components/FooterDelphus";
import FrTable from "../components/FrTable";
import { paramsToObject } from "../utils/functions";
import { Icon } from "react-icons-kit";
import { ic_add_box } from "react-icons-kit/md/ic_add_box";
import FrTextInput from "../components/FrTextInput";

const swal = withReactContent(Swal);

function Eventos() {
  const [data, setData] = useState({});
  const [updateType, setUpdateType] = useState(false);
  const [comboIev, setComboIev] = useState([{ value: "", display: "" }]);
  const [dataPev, setDataPev] = useState([{ value: "", display: "" }]);
  const [auth, setAuth] = useState(
    JSON.parse(
      localStorage["authApergs"] ||
        JSON.stringify({ authenticated: false, user: 0 })
    )
  );
  const [subTotal, setSubTotal] = useState(0);

  const columns = [
    {
      id: "display",
      numeric: false,
      label: "Participante",
      align: "left",
      minWidth: 150,
    },
    {
      id: "qtd_deps",
      numeric: true,
      label: "Qtd.Deps.",
      align: "left",
      minWidth: 50,
    },
    {
      id: "nometie",
      numeric: false,
      label: "Item",
      align: "left",
      minWidth: 100,
    },
    { id: "valor", numeric: true, label: "Valor", align: "left", minWidth: 90 },
  ];

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams);

      api.get("/eventos/iev?pk_eve=" + query.codigo).then((r) => {
        if (r.data) {
          setComboIev(r.data);
        }
      });

      if (query.codigo > 0) {
        setUpdateType(true);

        api
          .get("/eventos/pev?pk_eve=" + query.codigo + "&pk_ass=" + auth.pk_ass)
          .then((r) => {
            if (r.data[0] != undefined) {
              setDataPev(r.data);
            }
          });

        api.get("/eventos?pk_eve=" + query.codigo).then((r) => {
          if (r.data[0]) {
            setData(r.data[0]);
            swal.close();
          } else {
            swal
              .fire(
                "Código inválido",
                "Código informado não encontrado!",
                "warning"
              )
              .then((result) => {
                if (result.value) {
                  window.history.back();
                }
              });
          }
        });
      } else swal.close();
    };

    fetchData();
  }, []);

  useEffect(() => {
    totalizaPev();
  }, [dataPev]);

  function totalizaPev() {
    let total = dataPev.reduce(function (total, data) {
      return total + parseFloat(data.valor) * parseFloat(data.qtd_deps);
    }, 0);

    setSubTotal(total);
  }

  function consistData(data) {
    let required = [];

    let errors = [];

    required.forEach((curr, index) => {
      let fieldName = curr;

      if (!data[curr]) return errors.push(fieldName);
    });

    return errors;
  }

  function handleSubmit(e) {
    e.preventDefault();
    const auxValues = { ...data };
    auxValues.pk_pev = dataPev;
    auxValues.fk_ass = auth.pk_ass;
    const urlParams = new URLSearchParams(window.location.search);
    let query = paramsToObject(urlParams);
    auxValues.fk_eve = query.codigo;

    let errors = consistData(auxValues);

    if (errors.length === 0) {
      api.post("/eventos/add", auxValues).then((r) => {
        if (r.status === 200) {
          swal
            .fire({
              title: "Registro Lançado",
              text: "O registro lançado com sucesso!",
              icon: "success",
            })
            .then((result) => {
              if (result.value) {
                api
                  .get(
                    "/eventos/pev?pk_eve=" +
                      query.codigo +
                      "&pk_ass=" +
                      auth.pk_ass
                  )
                  .then((r) => {
                    if (r.data) {
                      setDataPev(r.data);
                    }
                  });
              }
            });
        }
      });
    }
  }

  function handleCancel(e) {
    e.preventDefault();
    swal
      .fire({
        title: "ATENÇÃO",
        text:
          "Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      })
      .then((result) => {
        if (result.value) {
          window.history.back();
        }
      });
  }

  const handleChange = (e) => {
    const auxValues = { ...data };
    switch (e.target.name) {
      case "fk_iev":
        if (e.target.value > 0) {
          api.get("/eventos?pk_iev=" + e.target.value).then((r) => {
            auxValues["nometie"] = r.data[0].nometie;
            auxValues["valor"] = r.data[0].valor;
            auxValues["participacao"] = r.data[0].participacao;
          });
        }
        auxValues[e.target.name] = e.target.value;
        break;

      default:
        auxValues[e.target.name] = e.target.value;
    }
    setData(auxValues);
  };

  const incluiPev = async (e) => {
    e.preventDefault();
    const auxValues = { ...data };

    if (auxValues["fk_iev"] > 0 && auxValues["qtd_deps"] > 0) {
      if (dataPev[0].display === "") {
        setDataPev([
          {
            pk_ass: auth.pk_ass,
            display: auth.name,
            nometie: auxValues["nometie"],
            fk_iev: auxValues["fk_iev"],
            valor: auxValues["valor"],
            participacao: auxValues["participacao"],
            qtd_deps: auxValues["qtd_deps"],
          },
        ]);
      } else {
        let item = [
          {
            pk_ass: dataPev[0].pk_ass,
            display: dataPev[0].display,
            nometie: dataPev[0].nometie,
            fk_iev: dataPev[0].fk_iev,
            valor: dataPev[0].valor,
            participacao: dataPev[0].participacao,
            qtd_deps: dataPev[0].qtd_deps,
            cancelado: dataPev[0].cancelado,
          },
        ];

        var i;
        for (i = 1; i < dataPev.length; i++) {
          item.push({
            pk_ass: dataPev[i].pk_ass,
            display: dataPev[i].display,
            nometie: dataPev[i].nometie,
            fk_iev: dataPev[i].fk_iev,
            valor: dataPev[i].valor,
            participacao: dataPev[i].participacao,
            qtd_deps: dataPev[i].qtd_deps,
            cancelado: dataPev[i].cancelado,
          });
        }

        item.push({
          pk_ass: auth.pk_ass,
          display: auth.name,
          nometie: auxValues["nometie"],
          fk_iev: auxValues["fk_iev"],
          valor: auxValues["valor"],
          participacao: auxValues["participacao"],
          qtd_deps: auxValues["qtd_deps"],
        });
        setDataPev(item);
      }
    } else
      swal.fire({
        title: "Erro!",
        text: "Informe a quantidade de dependentes e o item do evento!",
        icon: "warning",
      });
  };

  return (
    <div className="defaultReg">
      <NavBar />
      <div className="defaultRegContent">
        <div className="defaultHeaderReg">
          <UserOptions />
          <h3> Confirmação de Participação em Eventos</h3>
        </div>

        <br />
        <form id="regForm" onSubmit={handleSubmit}>
          <div class="row">
            <div class="column" style={{ width: 50, marginLeft: 20 }}>
              <label>Evento: </label>
            </div>

            <div class="column">
              <strong>{data.descricao}</strong>
            </div>
          </div>

          <div class="row">
            <div class="column" style={{ width: 50, marginLeft: 20 }}>
              <label>Data: </label>
            </div>

            <div class="column">
              <strong>{data.data}</strong>
            </div>
          </div>

          <div class="row">
            <div class="column" style={{ width: 50, marginLeft: 20 }}>
              <label>Local: </label>
            </div>

            <div class="column">
              <strong>{data.local}</strong>
            </div>
          </div>

          <hr width="100%" size="50" color=" #528b46" />

          <FrTextInput
            maxLength="100"
            value={auth.name || ""}
            color="#49573B"
            label="Associado"
            variant="outlined"
            size="small"
            style={{ width: 300 }}
            disabled
          />

          <FrTextInput
            maxLength="50"
            value={data.qtd_deps || ""}
            color="#49573B"
            label="Qtd.Deps."
            variant="outlined"
            size="small"
            style={{ width: 100 }}
            onChange={handleChange}
            name="qtd_deps"
            id="qtd_deps"
          />

          <FrSelect
            value={data.fk_iev || ""}
            onChange={handleChange}
            name="fk_iev"
            id="fk_iev"
            label="Item"
            data={comboIev}
            style={{ width: 300 }}
          />
          <button className="regButton" onClick={handleSubmit}>
            {" "}
            {"Adicionar"}
            <Icon icon={ic_add_box} size={15} />
          </button>

          <br />
          <hr width="100%" size="50" color="#004136" />
          <div className="tableDiv">
            <FrTable
              regName="regeventos"
              columns={columns}
              searched={false}
              codeName="pk_pev"
              page={"RegEventos"}
              data={dataPev}
              subTotal={subTotal}
              totaliza={true}
              enableDel={"S"}
            />
          </div>
        </form>
        <div className="regButtonsDiv">
          {/* <button onClick={handleSubmit}>Salvar</button> */}
          <button onClick={handleCancel}>Fechar</button>
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default Eventos;

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { Icon } from 'react-icons-kit'
import { ic_add_box } from 'react-icons-kit/md/ic_add_box'
import { ic_search } from 'react-icons-kit/md/ic_search'
import { ic_delete } from 'react-icons-kit/md/ic_delete'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import NavBar from '../components/NavBar'
import FrTable from '../components/FrTableReg'
import FrTextInput from '../components/FrTextInput'
import FrSelect from '../components/FrSelect'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FooterDelphus from '../components/FooterDelphus'
import { Modal } from 'react-bootstrap'
import FrTableReg from '../components/FrTableReg'


import api from '../services/api'
import { paramsToObject, formToObject, populateForm, objectToQueryString, isEmpty } from '../utils/functions'

import './styles/DefaultMainScreen.css';
import './styles/Manut.css';
import { SettingsInputHdmiOutlined } from '@material-ui/icons';

const swal = withReactContent(Swal)

function Financeiro() {
  const [data, setData] = useState([])
  const [filter, setFilter] = useState({})
  const [filtered, setFiltered] = useState(false)
  const [comboDep, setComboDep] = useState([{ value: '', display: '' }])
  const [comboSer, setComboSer] = useState([{ value: '', display: '' }])
  const [auth, setAuth] = useState(JSON.parse(localStorage['authApergs'] || JSON.stringify({ authenticated: false, user: 0 })));
  const [totalValor, setTotalValor] = useState(0)
  const [totalRecebido, setTotalRecebido] = useState(0)
  const [totalPendente, setTotalPendente] = useState(0)
  const [comboAno, setComboAno] = useState([{ value: '', display: '' }])
  const [comboMes, setComboMes] = useState([{ value: '', display: '' }])
  const [showModal, setshowModal] = useState(false)
  const [htmlDemo, setHtml] = useState('')
  const [dataDemo, setDataDemo] = useState([])

  const columns = [
    { id: 'nomeass', numeric: false, label: 'Associado', align: 'left', minWidth: 300 },
    { id: 'nomedep', numeric: false, label: 'Referente', align: 'left', minWidth: 300 },
    { id: 'competencia', numeric: false, label: 'Competência', align: 'left', minWidth: 100 },
    { id: 'valor', numeric: true, label: 'Valor', align: 'left', minWidth: 50 },
    { id: 'data_liq', numeric: false, label: 'Data Liquidação', align: 'left', minWidth: 50 },
    { id: 'nomeser', numeric: false, label: 'Tipo de Serviço', align: 'left', minWidth: 150 },
    { id: 'observacao', numeric: false, label: 'Observações', align: 'left', minWidth: 300 },
  ]

  const columnsDemo = [
    { id: 'nome', numeric: false, label: 'Arquivo', align: 'left', minWidth: 300 },
    { id: 'arquivo', numeric: false, label: 'Arquivo', align: 'left', minWidth: 300, icon:true },


  ]

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })


      setComboDep(await api.getComboDataWhere('associados', 'fk_ass=' + auth.pk_ass))
      setComboSer(await api.getComboData('tipos_servicos'))

      setComboMes([
        {
          "value": "01",
          "display": "Janeiro",
        },
        {
          "value": "02",
          "display": "Fevereiro",
        },
        {
          "value": "03",
          "display": "Março",
        },

        {
          "value": "04",
          "display": "Abril",
        },
        {
          "value": "05",
          "display": "Maio",
        },
        {
          "value": "06",
          "display": "Junho",
        },
        {
          "value": "07",
          "display": "Julho",
        },
        {
          "value": "08",
          "display": "Agosto",
        },
        {
          "value": "09",
          "display": "Setembro",
        },
        {
          "value": "10",
          "display": "Outubro",
        },
        {
          "value": "11",
          "display": "Novembro",
        },
        {
          "value": "12",
          "display": "Dezembro",
        },

      ])

      let ano = []
      let i
      for (i = 2020; i < 2041; i++) {
        ano.push({
          "value": i - 2000,
          "display": i,
        },
        )
      }

      setComboAno(ano);

      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)
      setFilter(query)
      populateForm(document.getElementById('searchForm'), query)

      swal.close()
      if (query.buscar === 'true') {
        let event = new Event('build');
        getData(event)
      }
    }

    fetchData()
  }, [])

  function getData(e) {
    e.preventDefault()
    var formData = new FormData(document.getElementById('searchForm'))
    var queryObject = formToObject(formData)
    queryObject.pk_ass = auth.pk_ass

    if (!isEmpty(queryObject)) {
      queryObject.buscar = true

      setFiltered(true)
      window.history.replaceState({ filtered: true }, 'filter', "/apergs_associado/financeiro?" + objectToQueryString(queryObject));


      swal.fire({
        html: <FrLoading text="Buscando dados..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      api.get('/financeiro', { params: queryObject }).then(r => {
        setData(r.data)
        try {
          setTotalValor(r.data[0].totalValor)
          setTotalRecebido(r.data[0].totalRecebido)
          setTotalPendente(r.data[0].totalPendente)
        } catch { }
        swal.close()
      })
    } else {
      swal.fire('Nenhum filtro informado!', 'Informe ao menos um filtro para realizar a busca.', 'warning')
    }
  }

  function clearForm(e) {
    e.preventDefault();
    console.log('limpa');
    window.history.replaceState({ filtered: false }, 'filter', '/apergs_associado/financeiro');
    document.getElementById('searchForm').reset()
    setFilter({})
  }

  function getDemoMes(e) {
    e.preventDefault();

    if ((filter.ano != undefined) && (filter.mes != undefined)) {
      api.get('/financeiro/demonstrativos?pk_ass=' + auth.pk_ass + '&competencia=' + filter.mes + filter.ano + '&ano=20' + filter.ano + '&mes=' + filter.mes + '&nomeass=' + auth.name).then(r => {
        if (r.data) {
          console.log(r.data)
          setDataDemo(r.data)
          setshowModal(true)

          // let htmlDemo = '<table style="width:100%"><tr><th>Arquivo</th><th></th></tr>' +
          //   '<tr><td>' + r.data[0].nome + '</td><td>' + '<a class="button" href="' + r.data[0].arquivo + '" target="_blank">Abrir</a></td></tr>'

          // r.data.map((row, index) => {
          //   if (index > 0)
          //     htmlDemo = htmlDemo + '<tr><td>' + row.nome + '</td><td>' + '<a class="button" href="' + row.arquivo + '" target="_blank">Abrir</a></td></tr>'

          //   if (index + 1 == r.data.length) {
          //     htmlDemo = htmlDemo + '</table>'

          //   setHtml(htmlDemo)
          //   setshowModal(true)

          // }
          // })

        }
        else {
          swal.fire('Nenhum demonstrativo encontrado!', 'Nenhum demonstrativo encontrado!', 'warning')
        }
      })
    } else {
      swal.fire('Nenhum filtro informado!', 'Informe a competência!', 'warning')
    }
  }

  function getDemoIR(e) {
    e.preventDefault();
    const auxValues = { ...filter };

    if (filter.ano != undefined) {
      window.open('https://associado.apergs.org.br:21001/static/demonstrativosIR/20' + filter.ano + '/' + auth.pk_ass + '_' + '20' + filter.ano + '.pdf', '_blank')

    } else {
      swal.fire('Nenhum filtro informado!', 'Informe a competência!', 'warning')
    }
  }

  function closeModal(e) {
    e.preventDefault();
    setshowModal(false);
  }



  const handleChange = (e) => {
    const auxValues = { ...filter };
    // if (e.target.name === 'competencia')
    //   auxValues[e.target.name] = formatCompetencia(e.target.value)
    // else
    auxValues[e.target.name] = e.target.value;
    setFilter(auxValues);
  };

  return (
    <div className="Manut">
      <NavBar />
      <div className="ManutContent">
        <div className="defaultHeader">
          <h3>Financeiro</h3>
          <UserOptions />
        </div>
        <br />
        <div className="filtersDiv">
          <form id="searchForm" onSubmit={getData}>
            <div>

              <FrTextInput
                value={auth.name || ''}
                label='Associado'
                variant='outlined'
                size='small'
                style={{ width: 300 }}
                disabled
              />

              <FrSelect
                value={filter.pk_dep || ''}
                style={{ width: 300 }}
                name="pk_dep"
                id="pk_dep"
                label='Dependente'
                data={comboDep}
                onChange={handleChange}
              />

              {/* <FrSelect
                value={filter.pk_ser || ''}
                style={{ width: 300 }}
                name="pk_ser"
                id="pk_ser"
                label='Tipo de Serviço'
                data={comboSer}
                onChange={handleChange}
              /> */}


              <FrSelect
                value={filter.mes || ''}
                style={{ width: 150 }}
                name="mes"
                id="mes"
                label='Competência/Débito'
                data={comboMes}
                onChange={handleChange}
              />

              <FrSelect
                value={filter.ano || ''}
                style={{ width: 70 }}
                name="ano"
                id="ano"
                // label='Ano'
                data={comboAno}
                onChange={handleChange}
              />


            </div>
            <div>
              <button onClick={getData}>
                <Icon icon={ic_search} size={18} />
                Pesquisar
              </button>

              <button onClick={getDemoMes}>
                Demonstrativo Mensal
              </button>

              <button onClick={getDemoIR}>
                Demonstrativo IR
              </button>

              <button onClick={clearForm}>
                <Icon icon={ic_delete} size={18} />
                Limpar
              </button>
            </div>
          </form>
        </div>

        <div className={(showModal) ? 'ModalShow' : 'ModalHide'} tabIndex="-1">
          <div >

            <Modal.Dialog className="Modal">
              <div>
                <Modal.Header className="ModalBg">
                  <div className="ModalHeader">
                    <h3 className="headerModal">Demonstrativos</h3>
                  </div>
                </Modal.Header>
                <Modal.Body className="ModalBg" >
                  <div >
                    <FrTableReg columns={columnsDemo} data={dataDemo} readonly={true} />
                  </div>
                </Modal.Body>

                <Modal.Footer className="ModalBg">
                  <div className="regButtonsDiv">
                    <button onClick={closeModal}>Sair</button>
                  </div>
                </Modal.Footer>
              </div>
            </Modal.Dialog>
          </div>

        </div>

        <br />
        <div className="tableDiv">
          <FrTable readonly={true} regName="financeiro" columns={columns} searched={filtered} codeName="pk_ctr" page={'Financeiro'} data={data} enableEdit={'N'} enableDel={'N'} emptyMessage={'Nenhum registro encontrado!'} />
        </div>
        <br />
        <div className="defaultFooter" hidden={data.length === 0}>
          <h3>
            <div class="row">

              <div class="column">
                TOTAL VALOR
                <div>
                  {totalValor}</div>

              </div>

              <div class="column">
                TOTAL RECEBIDO
              <div>
                  {totalRecebido}
                </div>
              </div>

              <div class="column">
                TOTAL PENDENTE
                <div>
                  {totalPendente}
                </div>

              </div>
            </div>

          </h3>

        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default Financeiro;

import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import api from "../services/api";

import "./styles/Login.css";

import logo from "../imgs/logo.png";
import { mCPF, mNum } from '../utils/masks'
import FrTextInput from '../components/FrTextInput'

const swal = withReactContent(Swal);

function Login() {

  const [cpf, setCpf] = useState('')
  const [auth, setAuth] = useState(
    JSON.parse(
      localStorage["authApergs"] ||
      JSON.stringify({ authenticated: false, user: 0 })
    )
  );

  useEffect(() => {
    if (auth.authenticated) window.location.href = "/apergs_associado/home";
  }, [auth]);

  function login(e) {
    e.preventDefault();
    //Pega valores do form
    const form = document.getElementById("loginForm");
    const data = new FormData(form);

    if (data.get("cpf") === "") {
      swal.fire("CPF não informado!", "Informe o CPF.", "warning");
      return;
    }

    if (data.get("senha") === "") {
      swal.fire("Senha não informada!", "Informe a senha.", "warning");
      return;
    }
    //Converte FormData em JSON
    var object = {};

    data.forEach(function (value, key) {
      if (key === 'cpf')
        object[key] = mNum(value)
      else
        object[key] = value;
    });

    //Checa no servidor
    api
      .post("usuarios/login", object)
      .then(function (response) {
        // console.log(response.data[0]);
        let { pk_usx, nome, ultimo_login, pk_ass } = response.data[0];
        if (pk_usx > 0) {
          localStorage["authApergs"] = JSON.stringify({
            authenticated: true,
            pk_ass: pk_ass,
            user: pk_usx,
            name: nome,
            ultimo_login,
            last_activity: new Date(),
          });
          window.location.href = "/apergs_associado/home";
        } else {
          swal.fire(
            "Login inválido!",
            "Verifique o Login e a Senha.",
            "warning"
          );
        }
      })
      .catch(function (error) {
        swal.fire(
          "Login inválido!",
          "Verifique o Login e a Senha.",
          "warning"
        );
      });
  }

  const handleChange = (e) => {
    switch (e.target.name) {
      case 'cpf':
        setCpf(mCPF(e.target.value))
        break
    }
  };


  return (
    <div className="Login">
      <div className="form">
        <img alt="fr_logo" src={logo} title="Apergs" className="logo" />

        <div className="headerlogin">Login</div>

        <form className="form-signin" id="loginForm" onSubmit={login}>
          <div className="form-label-group">
            <FrTextInput
              maxLength='18'
              name='cpf'
              id='cpf'
              label='CPF'
              variant='outlined'
              size='small'
              style={{ width: 190 }}
              onChange={handleChange}
              value={cpf}

            />
          </div>

          <div className="form-label-group">

            <FrTextInput
              name="senha"
              id="senha"
              label="Senha"
              variant="outlined"
              size="small"
              style={{ width: 190 }}
              input type="password"

            />

          </div>
          <button className="loginButton" type="submit" rel="nofollow">
            <span>
              <p className="loginButtonText">Entrar</p>
            </span>
          </button>
          <div className="footerlogin">
            <a href="/apergs_associado/forgotPw" >
              Esqueci minha senha
            </a>
            <br />
            <a href="/apergs_associado/cadastre_se" >
              Cadastre-se
            </a>

          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { makeStyles, withStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { ptBR } from '@material-ui/core/locale';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import IconButton from '@material-ui/core/IconButton';
import { Icon } from 'react-icons-kit'
import { ic_delete } from 'react-icons-kit/md/ic_delete'
import { ic_mode_edit } from 'react-icons-kit/md/ic_mode_edit'
import {eye} from 'react-icons-kit/icomoon/eye'
import { ic_local_printshop } from 'react-icons-kit/md/ic_local_printshop'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Swal from 'sweetalert2'
import Checkbox from '@material-ui/core/Checkbox';
import withReactContent from 'sweetalert2-react-content'
import { ic_watch_later } from 'react-icons-kit/md/ic_watch_later'
import { ic_gavel } from 'react-icons-kit/md/ic_gavel'
import { ic_thumbs_up_down } from 'react-icons-kit/md/ic_thumbs_up_down'
import { ic_work } from 'react-icons-kit/md/ic_work'
import { ic_check } from 'react-icons-kit/md/ic_check'
import { ic_cancel } from 'react-icons-kit/md/ic_cancel'
import { ic_event_note } from 'react-icons-kit/md/ic_event_note'
import Dialog from '@material-ui/core/Dialog';
import { MDBContainer, MDBIframe } from "mdbreact";
import { view } from 'react-icons-kit/ikons/view'

import api from '../../services/api'


import './index.css';
import Tooltip from '@material-ui/core/Tooltip';

const icons = [
  { fk_sne: 1, icon: (<Icon size={32} style={{ color: '#2A9D8F' }} icon={ic_thumbs_up_down} />) },
  { fk_sne: 2, icon: (<Icon size={32} style={{ color: '#E9C46A' }} icon={ic_work} />) },
  { fk_sne: 3, icon: (<Icon size={32} style={{ color: '#EF233C' }} icon={ic_gavel} />) },
]

const swal = withReactContent(Swal)

const theme = createMuiTheme({
  palette: {
    primary: { main: '#004136' },
  },
}, ptBR);

const FrCheckBox = withStyles({
  root: {
    color: 'black',
    '&$checked': {
      color: 'black',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);



const useStyles = makeStyles({
  root: {
    backgroundColor: '#e1e6e6',
    borderRadius: 5,
    borderColor: '#004136',
    border: '2px solid',

    "& .Mui-selected": {
      backgroundColor: '#99b3ae',//'#004136',
    },
    "& .Mui-selected:hover": {
      backgroundColor: '#99b3ae',//'#004136',

    },
    width: '100%',
  },
  container: {
    maxHeight: '58vh',
    minHeight: '58vh'
  },
});


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#004136',
    color: theme.palette.common.white,

  },
  body: {
    fontSize: 14,
    borderRadius: 5,
    borderColor: '#004136',
    border: '2px solid',
    color: 'black'

  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);



export default function FrTable(props) {
  const classes = useStyles();
  const createSortHandler = (property) => (event) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');    
    setOrderBy(property);
    
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setData] = useState(props.data)
  const [columns, setColumns] = useState(props.columns)
  const [order, setOrder] = React.useState(props.regName == 'informativos' ? 'desc' : 'asc');
  const [orderBy, setOrderBy] = React.useState(props.columns[0].id);
  const [selected, setSelected] = React.useState([]);
  const [opened, setOpened] = React.useState([]);
  const [auth, setAuth] = useState(JSON.parse(localStorage['authApergs'] || JSON.stringify({ authenticated: false, user: 0 })));
  const [showModal, setShowModal] = React.useState(false);
  const [arquivo, setArquivo] = React.useState(props.arquivo);

  function escondeModal(e) {
    e.preventDefault()
    setShowModal(false)
  }

  function ccyFormat(num) {
    if (num > 0)
      return `${num.toFixed(2)}`;
  }

  const auxSubtotal = props.subTotal;

  useEffect(() => {
    setData(props.data)
    setPage(0)
    if (props.arquivo) {
      setArquivo(props.arquivo)
      setShowModal(true)
    }
  }, [props.data])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    if (props.onChangeCheckbox) props.onChangeCheckbox(selected)
  }, [selected])

  function imprimeData(e, id) {
    e.preventDefault()
    if (props.regName=='normativas'){
      if (api.backendUrl == 'https://associado.apergs.org.br:21598'){
        window.open('https://sistema.apergs.org.br:21001/static/normativas/arquivos/'+id+".pdf")
      } else {
        window.open('https://apps.delphus.inf.br:21005/static/normativas/arquivos/'+id+".pdf")
      }
    } else {
      window.open(api.backendUrl + '/api/'+props.regName+'/pdf?'+props.codeName+'=' + id, '_blank');
    }

  }


  function handleAbreArquivo(e, arquivo) {
    e.preventDefault()

    if (props.arquivos) {
      let a = document.createElement('a');
      a.target = '_blank';
      a.href = arquivo
      a.click();
    } else {
      setArquivo(arquivo)
      setShowModal(true)
    }
  }


  function handleDelete(e, id, table) {
    e.preventDefault()
    swal.fire({
      title: 'ATENÇÃO',
      text: "Deseja excluir PERMANENTEMENTE o registro selecionado?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        console.log('/' + table + '/delete/' + id)

        api.post('/' + table + '/delete/' + id).then(r => {
          if (r.status === 200) {
            swal.fire({
              title: 'Registro Excluído',
              text: "O registro foi excluído com sucesso!",
              icon: 'success',
            }).then((result) => {
              if (result.value) {
                window.location.reload()
              }
            })
          } else {
            swal.fire({
              title: 'Erro!',
              text: "O registro não foi excluído!",
              icon: 'warning',
            })
          }
        })
      }
    })
  }

  async function handleCancel(e, pk) {
    e.preventDefault();
    await api.get('/reservas/getDiasReserva?pk=' + pk).then(r => {
      let msgMulta = ""

      if (r.data[0].multa == 0)
        msgMulta = " Não será aplicada nenhuma multa!"
      else
        msgMulta = "Será aplicada multa de " + r.data[0].multa + "% do valor da locação."

      swal.fire({
        title: 'ATENÇÃO',
        text: "O registro selecionado será cancelado! " + msgMulta + " Confirma?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não'
      }).then((result) => {
        if (result.value) {
          api.post('/reservas/cancelaReserva?pk_res=' + pk + '&multa=' + r.data[0].multa).then(r => {
            if (r.status === 200) {
              swal.fire({
                title: 'Registro Cancelado',
                text: "O registro foi cancelado com sucesso!",
                icon: 'success',
              }).then((result) => {
                if (result.value) {
                  window.location.reload()
                }
              });
            } else {
              swal.fire({
                title: 'Erro!',
                text: "O registro não foi cancelado!",
                icon: 'warning',
              })
            }
          });
        }
      });
    })
  }


  function handleDeleteItem(e, id, table) {
    e.preventDefault()
    swal.fire({
      title: 'ATENÇÃO',
      text: "Deseja cancelar sua participação no evento selecionado?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      console.log(id)
      if (result.value) {
        api.get('/eventos/prazoCancelamento?pk_eve=' + id).then(r => {
          console.log(r.data[0])
          if (r.data[0] != undefined) {
            api.post('/eventos/delete_pev?pk_eve=' + id + '&pk_ass=' + auth.pk_ass).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: 'Participação Cancelada',
                  text: "Participação cancelada com sucesso!",
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    window.location.reload()
                  }
                })
              } else {
                swal.fire({
                  title: 'Erro!',
                  text: "Não foi possível completar a operação!",
                  icon: 'warning',
                })
              }
            })

          } else {
            swal.fire({
              title: 'Erro!',
              text: "Prazo para cancelamento de participação neste evento expirado!",
              icon: 'warning',
            })

          }
        })

      }
    })
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n[props.codeName]);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    console.log(name)
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
    if (props.setSelected)
      props.setSelected(newSelected);
  };

  const handleClickDetail = (event, name) => {
    console.log(name)
    const selectedIndex = opened.indexOf(name);
    let newOpened = [];

    if (selectedIndex === -1) {
      newOpened = newOpened.concat(opened, name);
    } else if (selectedIndex === 0) {
      newOpened = newOpened.concat(opened.slice(1));
    } else if (selectedIndex === opened.length - 1) {
      newOpened = newOpened.concat(opened.slice(0, -1));
    } else if (selectedIndex > 0) {
      newOpened = newOpened.concat(
        opened.slice(0, selectedIndex),
        opened.slice(selectedIndex + 1),
      );
    }

    setOpened(newOpened);
  }

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const isOpened = (name) => opened.indexOf(name) !== -1;

  return (
    <ThemeProvider theme={theme}>
      {(data.length === 0) ? (
        <div>{props.searched ? 'Registro(s) não encontrado(s)!' : ''}</div>
      ) : (
        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            {/* <Table stickyHeader aria-label="sticky table"> */}
            <Table className={classes.table} size="small" aria-label="a dense table">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell padding="checkbox" >
                    <FrCheckBox
                      indeterminate={selected.length > 0 && selected.length < data.length}
                      checked={data.length > 0 && selected.length === data.length}
                      onChange={handleSelectAllClick}
                      inputProps={{ 'aria-label': 'select all desserts' }}
                      hidden={!props.selected}
                    />
                  </StyledTableCell>
                  {props.detail ? <StyledTableCell style={{ minWidth: 30, width: 30 }} /> : (<></>)}
                  {((!props.readOnly) || (props.pdf) || (props.eventos) || (props.arquivos) || (props.imprimeNormativa)) ? (
                    <StyledTableCell
                      style={{ minWidth: 150, width: 150 }}
                    >
                      Opções
                    </StyledTableCell>) : (<></>)}
                  {columns.map((column) => (
                    !column.hidden ?
                  <StyledTableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth}}
                  >
                    <TableSortLabel
                      active={orderBy === (column.colunaOrdem || column.id)}
                      direction={orderBy === (column.colunaOrdem || column.id) ? order : 'desc'}
                      onClick={column.colunaOrdem ? createSortHandler(column.colunaOrdem) : createSortHandler((column.order) ? column.order : column.id)}
                      style={{ color: 'white' }}
                      hidden={column.hidden}
                    >
                      {column.label}
                    </TableSortLabel>
                  </StyledTableCell> :
                  ''
                    
                  ))}
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {stableSort(data, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                  const isItemSelected = isSelected(row[props.codeName]);
                  const isItemOpened = isOpened(row[props.codeName]);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  if (props.detail) {
                    return (
                      <>
                        <StyledTableRow aria-checked={isItemSelected} hover role="checkbox" tabIndex={-1} key={row[props.codeName]} selected={isItemSelected}>
                          <TableCell padding="checkbox" onClick={(event) => handleClick(event, row[props.codeName])} style={{ backgroundColor: row['color'] }}>
                            <FrCheckBox
                              checked={isItemSelected}
                              inputProps={{ 'aria-labelledby': labelId }}
                              hidden={!props.selected}
                            />
                          </TableCell>
                          <TableCell style={{ backgroundColor: row['color'] }}>
                            <IconButton aria-label="expand row" size="small" onClick={(event) => handleClickDetail(event, row[props.codeName])}>
                              {isItemOpened ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                          </TableCell>


                          {(!props.readOnly) ? (

                            <TableCell>

                              <Link to={"/apergs_associado/" + props.regName + "/registro?codigo=" + row[props.codeName]}>
                                <Icon className="tableEdit" icon={ic_mode_edit} size={22} />
                              </Link>

                            </TableCell>
                          ) : (<></>)}

                          {((!props.readOnly) && (props.enableDel == 'S')) ? (
                            <TableCell>
                              <Icon className="tableDelete" icon={ic_delete} size={22} onClick={(e) => handleDelete(e, row[props.codeName], props.regName)} />

                            </TableCell>
                          ) : (<></>)}

                          {(props.enableCancel == 'S') ? (
                            <TableCell>
                              <Icon className="tableDelete" icon={ic_cancel} size={22} onClick={(e) => handleCancel(e, row[props.codeName])} />

                            </TableCell>
                          ) : (<></>)}

                          {columns.map((column) => {
                            const value = row[column.id];
                            if (column.image) {
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  <image src={value ? api.backendUrl + '/static/upload/lotes/' + row[props.codeName] + '/' + value : api.backendUrl + '/static/upload/lotes/no-image.jpg'} />
                                </TableCell>
                              )
                            } else if (column.icon) {
                              // let iconComponent = icons.find((item) => item.fk_sne === value)
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  {ic_check}
                                </TableCell>
                              )
                            } else {
                              return (
                                <TableCell key={column.id} align={column.align} >
                                  {column.format && typeof value === 'number' ? column.format(value) : value}
                                </TableCell>
                              );
                            }
                          })}
                        </StyledTableRow>
                      </>
                    );
                  } else {
                    return (
                      <StyledTableRow onClick={(event) => handleClick(event, row[props.codeName])} aria-checked={isItemSelected} hover role="checkbox" tabIndex={-1} key={row[props.codeName]} selected={isItemSelected}>
                        <TableCell padding="checkbox">
                          <FrCheckBox
                            checked={isItemSelected}
                            inputProps={{ 'aria-labelledby': labelId }}
                            hidden={!props.selected}
                          />
                        </TableCell>
                        {((!props.readOnly) || (props.pdf) || (props.eventos) || (props.arquivos) || (props.imprimeNormativa)) ? (
                          <TableCell>
                            {(!props.readOnly) ?
                              (props.regName=='eventos') ?
                              
                                (row.exige_confirmacao !== 'Não exige confirmação') ?
                                <Tooltip title="Edição">
                                <Link to={"/apergs_associado/" + props.regName + "/registro?codigo=" + row[props.codeName]}>

                                  <Icon className="tableEdit" icon={ic_mode_edit} size={22} />
                                </Link>
                              </Tooltip>
                              :
                                <></>
                              
                              :(
                              <Tooltip title="Edição">
                                <Link to={"/apergs_associado/" + props.regName + "/registro?codigo=" + row[props.codeName]}>

                                  <Icon className="tableEdit" icon={ic_mode_edit} size={22} />
                                </Link>
                              </Tooltip>
                              )

                              : (<></>)
                            }

                            {(props.enableDel == 'S') ? (<Icon className="tableDelete" icon={ic_delete} size={22} onClick={(e) => handleDelete(e, row[props.codeName], props.regName)} />)
                              : (<></>)}

                            {(props.pdf) ? (<Tooltip title="Impressão">
                              <Link to={""}>

                                <Icon className="tableDelete" icon={ic_local_printshop} size={22} onClick={(e) => imprimeData(e, row[props.codeName])} />
                              </Link>
                            </Tooltip>
                            )
                              : (<></>)}

                            {(props.imprimeNormativa) ? (<Tooltip title="Visualizar">
                              <Link to={""}>

                                <Icon className="tableDelete" icon={eye} size={22} onClick={(e) => imprimeData(e, row[props.codeName])} />
                              </Link>
                            </Tooltip>
                            )
                              : (<></>)}

                            {(props.enableCancel) ? (<Tooltip title="Cancelamento">
                              <Link to={""}>
                                <Icon className="tableDelete" icon={ic_cancel} size={22} onClick={(e) => handleCancel(e, row[props.codeName])} />
                              </Link>
                            </Tooltip>
                            )
                              : (<></>)}

                            {(props.eventos) ? (<Icon className="tableDelete" icon={ic_event_note} size={22} onClick={(e) => handleAbreArquivo(e, row['arquivo'])} />
                            )
                              : (<></>)}

                            {(props.arquivos) ? (<Icon className="tableDelete" icon={view} size={22} onClick={(e) => handleAbreArquivo(e, row['nome'])} />
                            )
                              : (<></>)}


                          </TableCell>
                        ) : (<></>)}



                        {columns.map((column) => {
                          const value = row[column.id];
                          if (column.image) {
                            return (
                              <TableCell key={column.id} align={column.align}>
                                <img src={value ? api.backendUrl + '/static/upload/lotes/' + row[props.codeName] + '/' + value : api.backendUrl + '/static/upload/lotes/no-image.jpg'} style={{ maxHeight: '60px' }} />
                              </TableCell>
                            )
                          } else if (column.icon) {
                            // let iconComponent = icons.find((item) => item.fk_sne === value)
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {(value > 0) ?
                                  <Icon icon={ic_check} size={15} />

                                  : <></>}

                                {(value > 0) ?
                                  <Tooltip title="Exclusão">
                                    <Icon icon={ic_delete} size={15} onClick={(e) => handleDeleteItem(e, row[props.codeName])} />

                                  </Tooltip>
                                  : <></>}
                              </TableCell>
                            )
                          } else {
                            return (
                              !column.hidden ? 
                              <TableCell key={column.id} align={column.align} >
                                {column.format && typeof value === 'number' ? column.format(value) : value}
                              </TableCell>
                              :
                              ''
                            );
                          }
                        })}
                      </StyledTableRow>
                    );
                  }
                })}




              </TableBody>

            </Table>

            {props.totaliza ?
              (<div>
                <TableRow>
                  <StyledTableCell style={{ minWidth: 100, width: 1050 }} rowSpan={4} />

                  <StyledTableCell style={{ minWidth: 100, width: 100 }}><strong>Valor Total</strong></StyledTableCell>
                  <StyledTableCell align="right"><strong>{ccyFormat(auxSubtotal)}</strong></StyledTableCell>
                </TableRow>
              </div>
              )

              : (<></>)}

            <div>
              <Dialog aria-labelledby="simple-dialog-title" open={showModal} fullWidth={true} maxWidth={'lg'}>
                <div className="FrModalFooter">
                  <div className="FrModalButtonsDiv">
                    <MDBContainer className="text-center">
                      <MDBIframe src={arquivo} />
                    </MDBContainer>
                    <button onClick={escondeModal}> Sair </button>
                  </div>
                </div>
              </Dialog>
            </div>


          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      )}
    </ThemeProvider>
  );
}

import {useState, useRef} from 'react'
import { MDBContainer, MDBIframe } from 'mdbreact';
import $ from 'jquery'
import './index.css'

export function MostraRegras(props) {
    const PdfIFrame = useRef()
    var containerEl = $("#container")[0];
    const [isDisabled, setIsDisabled] = useState(true)
    const data = props.regras ? props.regras.split('\n') : ['']
    console.log(data)
    
      function handleScroll(e) {
        console.log('scrollou', $('#container').height())
        let scrollTop = $('#container').scrollTop()
        let maxScroll = document.getElementById('container').scrollHeight - 501
        if (scrollTop >= maxScroll){
           setIsDisabled(false)
        }
        console.log($('#container').scrollTop(), document.getElementById('container').scrollHeight )
      }

      return (
        <div>

        <div className="container" id='container' onScroll={(e) => handleScroll(e)}>
            <div>
              {data.map((linha, i) => {
                return <p key={`${linha}${i}`}>{linha}</p>
              })}            
            </div>
            
        </div>
            <div className="FrModalButtonsDiv">
                    {/* <div className="text-center"> */}
                    
                    
                    {/* </div> */}
                <button onClick={props.onClose}> Cancelar </button>
                <button className="disabled" id={isDisabled ? 'disabled' : 'abled'} onClick={props.aceitar}> Aceitar </button>
            </div>
        </div>
        

      )
    }
    // <MDBContainer >
    //     <MDBIframe id='MDBIframeDiv' src={props.src} ref={PdfIFrame} />
    // </MDBContainer> 
export function mTel(tel) {
  tel = tel.replace(/\D/g, "")
  tel = tel.replace(/^(\d)/, "($1")
  tel = tel.replace(/(.{3})(\d)/, "$1)$2")
  if (tel.length === 9) {
    tel = tel.replace(/(.{1})$/, "-$1")
  } else if (tel.length === 10) {
    tel = tel.replace(/(.{2})$/, "-$1")
  } else if (tel.length === 11) {
    tel = tel.replace(/(.{3})$/, "-$1")
  } else if (tel.length === 12) {
    tel = tel.replace(/(.{4})$/, "-$1")
  } else if (tel.length > 12) {
    tel = tel.replace(/(.{4})$/, "-$1")
  }
  return tel;
}

export function mCNPJ(cnpj) {
  cnpj = cnpj.replace(/\D/g, "")
  cnpj = cnpj.replace(/^(\d{2})(\d)/, "$1.$2")
  cnpj = cnpj.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
  cnpj = cnpj.replace(/\.(\d{3})(\d)/, ".$1/$2")
  cnpj = cnpj.replace(/(\d{4})(\d)/, "$1-$2")
  return cnpj
}

export function mCPF(cpf) {
  cpf = cpf.replace(/\D/g, "")
  cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2")
  cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2")
  cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
  return cpf
}

export function mCEP(cep) {
  cep = cep.replace(/\D/g, "")
  cep = cep.replace(/^(\d{2})(\d)/, "$1.$2")
  cep = cep.replace(/\.(\d{3})(\d)/, ".$1-$2")
  return cep
}

export function mNum(num) {
  num = num.replace(/\D/g, "")
  return num
}


export function mData(data) {
  data = data.replace(/\D/g, "")
  data = data.substr(0,2)+"/"+data.substr(2,2)+"/"+data.substr(4,4)

  return data;
}

export function formatCompetencia(competencia) {
  competencia = competencia.replace(/\D/g, "")
  competencia = competencia.substr(0,2)+"/"+competencia.substr(3,2)

  return competencia;
}

export function mHora(hora) {
  hora = hora.replace(/\D/g, "")
  hora = hora.substr(0,2)+":"+hora.substr(2,2)

  return hora;
}

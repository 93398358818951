import React, { useEffect, useState } from "react";
import { Icon } from "react-icons-kit";
import { ic_add_box } from "react-icons-kit/md/ic_add_box";
import { ic_search } from 'react-icons-kit/md/ic_search'
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import NavBar from "../components/NavBar";
import FrTable from "../components/FrTable";
import UserOptions from "../components/UserOptions";
import FrLoading from "../components/FrLoading";
import FooterDelphus from "../components/FooterDelphus";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import FrSelect from "../components/FrSelect";
import { MDBContainer, MDBIframe } from "mdbreact";
import FrTableReg from "../components/FrTableReg";
import { Modal } from "react-bootstrap";
import moment, { now } from "moment";
import Nav from 'react-bootstrap/Nav'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FrDatePicker from "../components/FrDatePicker";
import { ic_delete } from 'react-icons-kit/md/ic_delete'

import api from "../services/api";

import {
  paramsToObject,
  formToObject,
  populateForm,
  objectToQueryString,
  isEmpty,
  dateSql,
} from "../utils/functions";

import "./styles/DefaultMainScreen.css";
import "./styles/Manut.css";
import { Dialog } from "@material-ui/core";
import { MostraRegras } from "../components/MostraRegras";

const swal = withReactContent(Swal);

function Reservas() {
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState({});
  const [filtered, setFiltered] = useState(false);
  const [auth, setAuth] = useState(
    JSON.parse(
      localStorage["authApergs"] ||
      JSON.stringify({ authenticated: false, user: 0 })
    )
  );
  const [selected, setSelected] = useState({});
  const [value, onChange] = useState(new Date());
  const [comboLoc, setComboLoc] = useState([{ value: "", display: "" }]);
  const [reservas, setReservas] = useState([]);
  const [loc, setLoc] = useState();
  const [showModal, setShowModal] = React.useState(false);
  const [showModalRegras, setShowModalRegras] = React.useState(false);
  const [link, setLink] = useState();
  const [arquivo, setArquivo] = useState();
  const [dias, setDias] = useState(0);
  const [reserva_exclusiva, setReservaExclusiva] = useState('N');
  const [taxa, setTaxa] = useState(0);
  const [comboAss, setComboAss] = useState([{ value: "", display: "" }]);

  let esconde = auth.pk_ass !== 1973

  // const PdfIFrame = React.useRef(null);
  
  const columns = [
    {
      id: "nomeass",
      numeric: false,
      label: "Associado",
      align: "left",
      minWidth: 250,
      hidden: esconde
    },

    {
      id: "data_format",
      numeric: false,
      label: "Data",
      align: "left",
      minWidth: 100,
      order: 'data'
    },
    {
      id: "nomeloc",
      numeric: false,
      label: "Local",
      align: "left",
      minWidth: 300,
    },
    {
      id: "exclusiva",
      numeric: false,
      label: "Exclusiva",
      align: "center",
      minWidth: 80,
    },

    {
      id: "nomesri",
      numeric: false,
      label: "Situação",
      align: "left",
      minWidth: 100,
    },
  ];

  const columnsReservas = [
    { id: "data", numeric: false, label: "Data", align: "left", minWidth: 100 },
    { id: "turno", numeric: false, label: "", align: "left", minWidth: 100 },
  ];

 

  function escondeModalRegras(){
    setShowModal(true)
    setShowModalRegras(false)
  }

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });

      setComboLoc(await api.getComboData("local"));
      setComboAss(await api.getComboData("associados"));

      await api.get('/reservas/regras_reservas').then(r => {
        console.log(r.data[0])
        setArquivo(r.data[0].regra_sede_social_text)
      })

      await api.get("/reservas/pegaParametros").then((r) => {
        setLink("https://associado.apergs.org.br:21001/static/" + r.data[0].regras_sede);
        
        setDias(r.data[0].dias_reserva);

      });

      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams);
      setFilter(query);
      populateForm(document.getElementById("searchForm"), query);

      swal.close();
      if (query.buscar === "true") {
        let event = new Event("build");
        getData(event);
      }
    };

    fetchData();
  }, []);

  function mostraRegras(e) {
    e.preventDefault();
    window.open(link, "_blank");
  }

  function clearForm(e) {
    e.preventDefault();
    window.history.replaceState({ filtered: false }, 'filter', '/apergs_associado/reservas');
    document.getElementById('searchForm').reset()
    setFilter({})
  }

  async function escondeModal(e) {
    e.preventDefault();
    if (loc > 0) {
      let temExclusiva = false
      let temNormal = false
      let temReserva = false


      await api.get("/reservas/temExclusiva?data=" + dateSql(value)).then((r) => {
        if ((r.data[0]) && (r.data[0].pk_res)) {
          temExclusiva = true
        }
      })

      await api.get("/reservas/temNormal?data=" + dateSql(value)).then((r) => {
        if ((r.data[0]) && (r.data[0].pk_res)) {
          temNormal = true
        }
      })

      await api.get("/reservas/consisteReserva?data=" + moment(value).format("DD/MM/yyyy") + '&pk_loc=' + loc).then((r) => {
        if ((r.data[0]) && (r.data[0].pk_res)) {
          temReserva = true
        }
      })

      if (temExclusiva) {
        swal.fire({
          title: "ATENÇÃO",
          text: "Nova reserva não permitida pois existe reserva exclusiva nesta data! Deseja entrar em lista de espera?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
        }).then((result) => {
          if (result.value) {
            let auxValues = { pk_ass: auth.pk_ass, data: moment(value).format("DD/MM/yyyy"), fk_loc: loc, reserva_exclusiva: reserva_exclusiva }
            api
              .post("/reservas/listaEspera", auxValues)
              .then((r) => {
                if (r.status === 200) {
                  swal
                    .fire({
                      title:
                        "Registro lançado em lista de espera",
                      text: "O registro foi lançado em lista de espera!",
                      icon: "success",
                    })
                  setShowModal(false)
                }
              })
          } else
            setShowModal(false)
        })
      } else
        if ((reserva_exclusiva == 'S') && (temNormal)) {
          swal.fire({
            title: "ATENÇÃO",
            text: "Já existe reserva em outro local. Não permitido reserva exclusiva. Confirma reserva normal?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim",
            cancelButtonText: "Não",
          }).then((result) => {
            if (result.value) {
              let auxValues = [{}]
              window.location.href =
              "/apergs_associado/reservas/registro?data=" +
              moment(value).format("DD/MM/yyyy") +
              "&fk_loc=" +
              loc + '&reserva_exclusiva=' + 'N';
            } else
              setShowModal(false)
          })

        } else
          if (temReserva) {
            swal.fire({
              title: "ATENÇÃO",
              text: "Já existe reserva para este local nesta data! Deseja entrar em lista de espera?",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Sim",
              cancelButtonText: "Não",
            }).then((result) => {
              if (result.value) {
                let auxValues = { pk_ass: auth.pk_ass, data: moment(value).format("DD/MM/yyyy"), fk_loc: loc, reserva_exclusiva: reserva_exclusiva }
                api
                  .post("/reservas/listaEspera", auxValues)
                  .then((r) => {
                    if (r.status === 200) {
                      swal
                        .fire({
                          title:
                            "Registro lançado em lista de espera",
                          text: "O registro foi lançado em lista de espera!",
                          icon: "success",
                        })
                      setShowModal(false)
                    }
                  })
              } else
                setShowModal(false)
            })
          }
          else {
            setShowModal(false);
            setShowModalRegras(true)

          }

    } else {
      swal.fire({
        title: "ATENÇÃO",
        text: "Informe o local!",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
        cancelButtonText: "Não",
      });
    }
  }

  function confirmaModalRegras(){
    window.location.href =
    "/apergs_associado/reservas/registro?data=" +
    moment(value).format("DD/MM/yyyy") +
    "&fk_loc=" +
    loc + '&reserva_exclusiva=' + reserva_exclusiva;
  }
 

  useEffect(() => {
    const fetchDia = async () => {
     



      if ((dias > 0)) {
        var dataReserva = moment(value, 'DD-MM-YYYY');
        var today = moment();
        let diferenca = dataReserva.diff(today, 'days');
        // alert(diferenca)
        if (diferenca < dias)
          swal.fire({
            title: "ATENÇÃO",
            text: "Reservas não permitidas com menos de " + dias + " dias de antecedência!",
            icon: "warning",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
            cancelButtonText: "Não",
          });
      }

      setReservas([]);
      api
        .get("/reservas/pegaReservas?fk_loc=" + loc + "&data=" + dateSql(value))
        .then((r) => {
          setReservas(r.data);
        });
    };
    fetchDia();
  }, [value]);

  useEffect(() => {
    const fetchDia = async () => {
      setReservas([]);
      api
        .get("/reservas/pegaReservas?fk_loc=" + loc + "&data=" + dateSql(value))
        .then((r) => {
          setReservas(r.data);
        });
    };
    fetchDia();
  }, [loc]);

  function getData(e) {
    e.preventDefault();
    var formData = new FormData(document.getElementById("searchForm"));
    var queryObject = formToObject(formData);
    queryObject.pk_ass = auth.pk_ass;

    if (!isEmpty(queryObject)) {
      queryObject.buscar = true;

      setFiltered(true);
      window.history.replaceState(
        { filtered: true },
        "filter",
        "/apergs_associado/reservas?" + objectToQueryString(queryObject)
      );

      swal.fire({
        html: <FrLoading text="Buscando dados..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
      api.get("/reservas", { params: queryObject }).then((r) => {
        setData(r.data);
        swal.close();
      });
    } else {
      swal.fire(
        "Nenhum filtro informado!",
        "Informe ao menos um filtro para realizar a busca.",
        "warning"
      );
    }
  }


  const handleChangeFilter = (e) => {
    const auxValues = { ...filter };
    auxValues[e.target.name] = e.target.value;
    setFilter(auxValues);
  };



  const handleChange = (e) => {
    switch (e.target.name) {
      case 'reserva_exclusiva':
        setReservaExclusiva(reserva_exclusiva === 'S' ? 'N' : 'S')
        break
      case 'fk_loc':
        setLoc(e.target.value);
        setReservaExclusiva('N')
        api.get("/local?pk_loc=" + e.target.value).then((r) => {
          if (r.data[0].taxa_reserva_exclusiva > 0)
            setTaxa(r.data[0].taxa_reserva_exclusiva)
          else
            setTaxa(0)
        })

        break
    }
  };

  async function novoRegistro(e) {
    e.preventDefault();
    api.get('/associados?pk_ass='+auth.pk_ass).then(r => {
      console.log(r.data[0])
      if(r.data[0].inadimplente == 'S'){
        swal.fire('Falha ao reservar!', 'Contatar o administrativo da APERGS', 'error')
      } else {
        setShowModal(true);

      }
    })
  }

 

  return (
    <div className="Manut">
      <NavBar />
      <div className="ManutContent">
        <div className="defaultHeader">
          <h3>Reservas</h3>
          <UserOptions />
        </div>
        <br />
        <div className="filtersDiv">
          <form id="searchForm" onSubmit={getData}>
            <div>

              <FrSelect
                value={filter.associado || ''}
                style={{ width: 300 }}
                name="associado"
                id="associado"
                data={comboAss}
                label={auth.pk_ass !== 1973 ? "" : "Associado"}
                hidden={auth.pk_ass !== 1973}
                onChange={handleChangeFilter}
              />



              <FrDatePicker
                value={(filter.dataini || '').split('T')[0]}
                name="dataini"
                id="dataini"
                color="#528b46"
                label="Período Reserva"
                variant="outlined"
                size="small"
                style={{ width: 190 }}
                onChange={handleChangeFilter}
                hidden={auth.pk_ass !== 1973}
              />

              <FrDatePicker
                value={(filter.datafim || '').split('T')[0]}
                name="datafim"
                id="datafim"
                color="#528b46"
                variant="outlined"
                size="small"
                style={{ width: 190 }}
                onChange={handleChangeFilter}
                hidden={auth.pk_ass !== 1973}
              />

              <div>
                <button hidden={auth.pk_ass !== 1973}
                  onClick={getData}>
                  <Icon icon={ic_search} size={18} />
                  Pesquisar
                </button>

                <button onClick={(e) => novoRegistro(e)}>
                  <Icon icon={ic_add_box} size={18} />
                  Reservar
                </button>

                <button hidden={auth.pk_ass !== 1973}
                  onClick={clearForm}>
                  <Icon icon={ic_delete} size={18} />
                  Limpar
                </button>
              </div>
            </div>
          </form>
        </div>
        <br />

        <div>
          <Dialog aria-labelledby="simple-dialog-title" open={showModalRegras} fullWidth={true} maxWidth={'md'}>
            <MostraRegras regras={arquivo} onClose={escondeModalRegras} aceitar={confirmaModalRegras}/>
            <div className="FrModalFooter">
              <div className="FrModalButtonsDiv">
                {/* <div className="text-center"> */}
                  

                {/* </div> */}
                
              </div>
            </div>
          </Dialog>
          <div hidden={!showModal}>
            <Modal.Dialog className="Modal">
              <div>
                <Modal.Body className="ModalBg">
                  <div style={{ padding: "10px" }}>
                    <FrSelect
                      name="fk_loc"
                      id="fk_loc"
                      label="Dependência a ser ocupada"
                      data={comboLoc}
                      style={{ width: 300 }}
                      onChange={handleChange}
                    />

                    <FormControlLabel
                      hidden={taxa == 0}
                      control={
                        <Checkbox
                          value={reserva_exclusiva || ''}
                          checked={reserva_exclusiva === 'S'}
                          name='reserva_exclusiva'
                          id='reserva_exclusiva'
                          size='small'
                          onChange={handleChange}
                          color="primary"
                          style={{ marginRight: '-5px', marginLeft: '5px' }}

                        />
                      }
                      label="Reserva Exclusiva"
                    />


                    <Calendar
                      className="Calendar"
                      onChange={onChange}
                      value={value}
                    />
                  </div>

                  <div style={{ padding: "10px" }}>
                    <FrTableReg
                      regName=""
                      columns={columnsReservas}
                      data={reservas}
                      readonly={true}
                    />
                  </div>
                </Modal.Body>
              </div>
              <Modal.Footer>
                <div className="regButtonsDiv">
                  
                  <button style={{ width: "150px" }} onClick={mostraRegras}>
                    {" "}
                    Regras de Utilização{" "}
                  </button>
                  <button onClick={escondeModal}> OK </button>
                </div>
              </Modal.Footer>
            </Modal.Dialog>
          </div>
        </div>

        <div className="tableDiv" hidden={showModal}>
          <FrTable
            regName="reservas"
            columns={columns}
            searched={filtered}
            codeName="pk_res"
            page={"Reservas"}
            data={data}
            enableDel={"N"}
            pdf={true}
            enableCancel={"S"}
            emptyMessage={"Nenhuma reserva encontrada!"}
            // codeName={"pk_res"}
          />
        </div>
        <br />
        <FooterDelphus />
      </div>
    </div>
  );
}

export default Reservas;

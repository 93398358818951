import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import NavBar from '../components/NavBar'
import FrTable from '../components/FrTable'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FooterDelphus from '../components/FooterDelphus'
import { ic_search } from 'react-icons-kit/md/ic_search'
import { ic_delete } from 'react-icons-kit/md/ic_delete'
import { Icon } from 'react-icons-kit'
import FrDatePicker from '../components/FrDatePicker';

import api from '../services/api'


import { paramsToObject, formToObject, populateForm, objectToQueryString, isEmpty } from '../utils/functions'


import './styles/DefaultMainScreen.css';
import './styles/Manut.css';

const swal = withReactContent(Swal)

function Informativos() {
  const [data, setData] = useState([])
  const [filter, setFilter] = useState({})
  const [filtered, setFiltered] = useState(false)
  const [auth, setAuth] = useState(JSON.parse(localStorage['authApergs'] || JSON.stringify({ authenticated: false, user: 0 })));
  const [arquivo, setArquivo] = useState('')
  const [abreAutomatico, setAbreAutomatico] = useState(true)


  const columns = [
    // { id: 'auxdata', numeric: false, label: 'Data', align: 'left', minWidth: 100 },
    { id: 'pk_inf', numeric: false, label: 'Código', align: 'left', minWidth:0, hidden: true, order:'desc'},
    { id: 'descricao', numeric: false, label: 'Descrição', align: 'left', minWidth: 300 },
  ]

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })

      if (abreAutomatico) {
        api.get('/informativos').then(r => {
          setArquivo(r.data[0].link)
        })
      }

      swal.close()
      let event = new Event('build');
      getData(event)
      
    }

    fetchData()
  }, [])

  function getData(e) {
    e.preventDefault()
    var formData = new FormData(document.getElementById('searchForm'))
    var queryObject = formToObject(formData)
    setAbreAutomatico(false)
    setArquivo('')
    queryObject.buscar = true

    setFiltered(true)
    window.history.replaceState({ filtered: true }, 'filter', "/apergs_associado/informativos?" + objectToQueryString(queryObject));

    swal.fire({
      html: <FrLoading text="Buscando dados..." />,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false
    })

    api.get('/informativos', { params: queryObject }).then(r => {
      setData(r.data)

      swal.close()
    })
  }

  function clearForm(e) {
    e.preventDefault();
    console.log('limpa');
    window.history.replaceState({ filtered: false }, 'filter', '/apergs_associado/informativos');
    document.getElementById('searchForm').reset()
    setFilter({})
  }

  const handleChange = (e) => {
    const auxValues = { ...filter };
    auxValues[e.target.name] = e.target.value;
    setFilter(auxValues);
  };



  return (
    <div className="Manut">
      <NavBar />
      <div className="ManutContent">
        <div className="defaultHeader">
          <h3>Informativos da Associação</h3>
          <UserOptions />
        </div>
        <br />
        <div className="filtersDiv">
          <form id="searchForm" onSubmit={getData}>
            <div>

              <FrDatePicker
                value={(filter.dataini || '').split('T')[0]}
                name="dataini"
                id="dataini"
                color="#49573B"
                label="Período do Informativo"
                variant="outlined"
                size="small"
                style={{ width: 190 }}
                onChange={handleChange}

              />
              <FrDatePicker
                value={(filter.datafim || '').split('T')[0]}
                name="datafim"
                id="datafim"
                color="#49573B"
                variant="outlined"
                size="small"
                style={{ width: 190 }}
                onChange={handleChange}

              />



            </div>
            <div>
              <button onClick={getData}>
                <Icon icon={ic_search} size={18} />
                Pesquisar
              </button>

              <button onClick={clearForm}>
                <Icon icon={ic_delete} size={18} />
                Limpar
              </button>
            </div>
          </form>
        </div>

        <br />
        <div className="tableDiv">
          <FrTable arquivo={arquivo} eventos={true} regName="informativos" columns={columns} searched={filtered} codeName="pk_inf" page={'Informativos'} data={data} enableDelete={false} readOnly={true} />
        </div>
        <br />
        <FooterDelphus />
      </div>
    </div>
  );
}

export default Informativos;
